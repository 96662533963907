import styled from 'styled-components'

export const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	/* width: 702px;*/

	width: 100%;
	/* height: 417px; */

	/* table/border */

	margin-bottom: 20px;

	border: 1px solid #e4e4e4;
`

export const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	/* width: 702px;*/
	width: 100%;

	background: #ffffff;
`

export const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 120px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	/* width: 602px; */
	flex: 1;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

export const RowDataName = styled.div`
	box-sizing: border-box;

	width: 120px;
	height: 37px;
	line-height: 37px;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;
	border-collapse: collapse;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

export const RowDataContent = styled.div`
	box-sizing: border-box;

	display: flex;
	align-items: center;

	/* width: 602px;*/
	flex: 1;
	height: 37px;
	line-height: 37px;
	padding: 5px;
	background: #ffffff;
	/* table/border */
	border-collapse: collapse;

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`
export const Input = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 100%;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

export const ContentEditorName = styled.div`
	box-sizing: border-box;

	width: 120px;
	/* height: 350px; */
	line-height: 350px;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

export const ContentEditor = styled.div`
	box-sizing: border-box;

	/* width: 602px;
	 */
	flex: 1;
	min-height: 300px;
	height: 620px;
	max-height: 1000px;
	/* padding: 5px; */

	overflow-y: auto;
	overflow-x: hidden;
	padding: 5px;
	border: 1px solid #e4e4e4;
	background: #ffffff;
	/* table/border */
	/* 
	border-width: 1px 0px 0px 1px; */
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`
export const CkEditorSection = styled.div`
	height: 600px;
	max-height: 700px !important;
	z-index: 999;
`
export const CancelButton = styled.button`
	padding: 0px 10px;

	width: 68px;
	text-align: center;
	height: 32px;

	/* content/accentRed */

	background: #d44242;
	border-radius: 2px;
	border: none;

	font-size: 14px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;
`
