import React, { useState } from 'react'
import { useHeaderBtn } from 'app/hooks/stateStore/headerButton'
import { NavigationButtonText, NavigationButtonWrap } from './styles'

interface IProps {
	label: string
	onPress?: () => void
	data?: any
	notIcon?: boolean
	active?: boolean
	style?: any
}

const HeaderButton = ({ label, onPress, data, notIcon, active, style }: IProps) => {
	const { data: headerBtn, mutate } = useHeaderBtn()
	const [hoverState, setHoverState] = useState(false)

	if (headerBtn?.hoverState === undefined) {
		mutate({
			hoverState: false,
		})
	}

	return (
		<NavigationButtonWrap
			style={
				active
					? {
							color: '#144fa8',
							fontWeight: 'bold',
					  }
					: {
							color: '#333333',
					  }
			}
			hover={headerBtn?.hoverState}
			onClick={onPress}
			// onClick={data.length < 1 ? onPress : () => {}}
			onMouseOut={() => setHoverState(false)}
			onMouseOver={() => {
				mutate({
					hoverState: true,
				})
				setHoverState(true)
			}}>
			<NavigationButtonText style={{ ...style }}>{label}</NavigationButtonText>
			{/* <HeaderDropdown data={data} /> */}
		</NavigationButtonWrap>
	)
}

export default React.memo(HeaderButton)
