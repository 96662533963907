// export const API_URL = 'https://coinapi.cilabs.co.kr/v1' // API URL
export const API_URL = 'http://49.247.173.50:8080/v1' // API URL

export const UPLOAD_URL = 'http://49.247.173.50:8080' // API URL
export const USERINFO = '/user/my/info' // 사용자 정보
export const MYSETTINGS = '/user/my/settings' // 내 설정
export const MYBALANCE = '/user/my/balance' // 내 자산
export const MYTRADESETTINGS = '/user/my/tradesetting' // 내 트레이딩설정

// Created By 태신
export const TRANSFER = '/user/my/transfer'

export const EXCHANGESETTINGS = '/exchanges' // 거래쌍 설정
export const ORDERBOOK = '/trade/orderbooks' // 오더북
export const CANCELORDER = '/trade/cancel' // 주문취소

export const TRADELIST = '/trade/tradelist' // 주문취소
