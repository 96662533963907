import React, { useState, useEffect } from 'react'
import * as antd from 'antd'
import { useToken, useWindowDimensions } from 'app/hooks/'
import API from 'utils/api'
import {
	ControllerWrap,
	ButtonWrap,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	CreateButton,
	UpdateButton,
	SDeleteButton,
} from 'styles/globalPage-styles'
import { CancelButton } from '../../BoardManage/styles'
import CreateForm from './CreateForm'
import setting from 'assets/icons/setting.png'
import downicon from 'assets/icons/icon=down.png'
import upicon from 'assets/icons/icon=up.png'
import downleft from 'assets/icons/icon=down-left.png'

import {
	Content,
	SectionLeft,
	MenuList,
	Header,
	List,
	ClickedMenuItem,
	ClickedSubMenuItem,
	SubMenuItem,
	MenuItem,
	ArrowButtonWrap,
	SectionRight,
	MenuForm,
	MenuInfoWrap,
	FormItemTitle,
	Select,
	Input,
} from './styles'
import CIUtils from 'utils/ciutils'
import { View } from 'styles/reactnative'

const NewMenuManagementTable = () => {
	const { width } = useWindowDimensions()
	const { data: token } = useToken()
	const [createModal, setCreateModal] = useState(false)
	const [data, setData] = useState<any>()
	const [edit, setEdit] = useState(false)
	const [mainMenus, setMainMenus] = useState<any>([])
	const [subMenus, setSubMenus] = useState<any>([])
	const [originalMainMenus, setOriginalMainMenus] = useState<any>([])
	const [originalMenus, setOriginalMenus] = useState<any>([])
	const [deleteMenus, setDeleteMenus] = useState<any>([])

	const [name, setName] = useState(data ? data.name : '')
	const [path, setPath] = useState(data ? data.path : '')
	const [parent, setParent] = useState(data ? data.parent_menuid : 0)

	const [clickedMenu, setClickedMenu] = useState<any>()

	const deepCopy = (array: any) => {
		let temp: any = []
		array.map(item => temp.push({ ...item }))
		setOriginalMenus(temp)
	}

	const getMenus = () => {
		let apiurl = `/system/menus`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					let mainMenu = res.result.result.filter(menu => menu.level === 1)

					const orginalMainMenu = res.result.originalMenu.filter(menu => menu.level === 1)

					let tempSubMenu: any = []
					mainMenu.map((menu, index) => {
						const subMenu = res.result.result.filter(sub => menu.id === sub.parent_menuid)

						subMenu.map(item => {
							tempSubMenu.push({ ...item })
						})
					})

					setMainMenus(mainMenu)
					setSubMenus(tempSubMenu)
					deepCopy(res.result.result)
					setOriginalMainMenus(orginalMainMenu)
				}
			})
			.catch((err: any) => {})
	}

	const validateUpdateMenu = () => {
		const menu = clickedMenu
		const parent_menuid = parent

		if (menu.name !== name && name.length > 20) {
			antd.message.error('메뉴명은 20자이하로 작성해주세요')
			return false
		}

		if (menu.path !== path && !CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 20자 이하의 영문, 숫자로 작성해주세요')
			return false
		}

		if (menu.parent_menuid === parent_menuid) {
			let tempMainMenus: any = []

			menu.level === 1 ? (tempMainMenus = [...mainMenus]) : (tempMainMenus = [...subMenus])

			const index = tempMainMenus.indexOf(menu)
			const find = tempMainMenus.find(m => m.id !== menu.id && (m.name === name || m.path === path))

			if (find) {
				antd.message.error('경로 또는 이름이 중복되는 메뉴가 있습니다')
				return false
			}

			const { order, level } = menu
			return { order, level }
		} else {
			if (menu.parent_menuid && !parent_menuid) {
				const find = mainMenus.find(m => m.name === name || m.path === path)

				if (find) {
					antd.message.error('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}

				const order = mainMenus.length + 1
				const level = 1
				return { order, level }
			} else if (!menu.parent_menuid && parent_menuid) {
				const find = subMenus.find(
					m => m.parent_menuid === parent_menuid && (m.name === name || m.path === path)
				)

				if (find) {
					antd.message.error('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}
				//ccc
				const bro = subMenus.filter(m => m.parent_menuid === parent_menuid)

				const order = bro ? bro.length + 1 : 1
				const level = 2
				return { order, level }
			} else {
				const find = subMenus.find(
					m => m.parent_menuid === parent_menuid && (m.name === name || m.path === path)
				)

				if (find) {
					antd.message.error('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}

				const bro = subMenus.filter(m => m.parent_menuid === parent_menuid)

				const order = bro ? bro.length + 1 : 1
				const level = 2
				return { order, level }
			}
		}
	}

	const update = () => {
		let apiurl = `/system/menus`

		const updateMenus = validate()

		if (updateMenus.length < 1 && deleteMenus.length < 1) {
			antd.message.error('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', {
			updateMenus,
			deleteMenus,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setEdit(false)
					getMenus()
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		getMenus()
	}, [])

	const move = (direction: 'top' | 'up' | 'down' | 'bottom') => {
		if (!clickedMenu) return

		let menu = clickedMenu

		if (direction === 'up' && menu.order === 1) {
			return
		}

		if (menu.level === 1) {
			if (direction === 'down' && menu.order === mainMenus.length) {
				return
			}

			let tempMenus = [...mainMenus]
			const index = tempMenus.indexOf(menu)

			if (direction === 'up') {
				tempMenus[index].order = tempMenus[index].order - 1
				tempMenus[index - 1].order = tempMenus[index].order + 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index - 1]
				tempMenus[index - 1] = tempMenu
			} else {
				tempMenus[index].order = tempMenus[index].order + 1
				tempMenus[index + 1].order = tempMenus[index].order - 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index + 1]
				tempMenus[index + 1] = tempMenu
			}
			setMainMenus(tempMenus)
		}

		if (menu.level === 2) {
			const filter = subMenus.filter(s => s.parent_menuid === menu.parent_menuid)
			if (direction === 'down' && menu.order === filter.length) {
				return
			}

			let tempMenus = [...subMenus]
			const index = tempMenus.indexOf(menu)

			if (direction === 'up') {
				tempMenus[index].order = tempMenus[index].order - 1
				tempMenus[index - 1].order = tempMenus[index].order + 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index - 1]
				tempMenus[index - 1] = tempMenu
			}

			if (direction === 'down') {
				tempMenus[index].order = tempMenus[index].order + 1
				tempMenus[index + 1].order = tempMenus[index].order - 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index + 1]
				tempMenus[index + 1] = tempMenu
			}
			setSubMenus(tempMenus)
		}
	}

	const deleteMenu = () => {
		const menu = clickedMenu

		if (!clickedMenu) return

		if (menu.id == 45) {
			antd.message.error('게시판 관리에 대한 삭제는 불가합니다')
			return
		}

		setDeleteMenus(prev => [...prev, { id: menu.id }])
		sliceMenu(menu)

		setName('')
		setPath('')
		setParent(0)
		setClickedMenu(null)
	}

	const sliceMenu = (menu: any, menuArr?: any) => {
		if (menu.level === 1) {
			let temp = [...mainMenus]
			const index = temp.indexOf(menu)
			let front = temp.slice(0, index)
			let back = temp.slice(index + 1, temp.length)

			if (back.length > 1) {
				back.map(m => [(m.order = m.order - 1)])
			}

			temp = [...front, ...back]
			setMainMenus(temp)
		}

		if (menu.level === 2) {
			let temp: any = []
			if (menuArr) temp = [...menuArr]
			else temp = [...subMenus]
			// let temp = [...subMenus]
			const index = temp.indexOf(menu)
			let front = temp.slice(0, index)
			let back = temp.slice(index + 1, temp.length)

			let order = 1
			// let lastOrder = menu.lastOrder - 1
			temp = [...front, ...back]

			temp.map(m => {
				if (m.parent_menuid === menu.parent_menuid) {
					m.order = order
					// m.lastOrder = lastOrder
					order++
				}
			})
			setSubMenus(temp)
		}
	}

	const validate = () => {
		let updateMenus: any = []
		// let newMenus: any = []

		let temp = [...mainMenus, ...subMenus]

		temp.map(menu => {
			const find = originalMenus.find(originMenu => originMenu.id === menu.id)

			if (!find) {
				// newMenus.push(menu)
			} else {
				const menu1 = JSON.stringify(menu)
				const menu2 = JSON.stringify(find)

				if (menu1 === menu2) {
					return
				}
				let updateMenu: any = {}
				updateMenu = { id: menu.id, name: menu.name }

				if (find.name !== menu.name) {
					updateMenu = { ...updateMenu, name: menu.name }
				}

				if (find.path !== menu.path) {
					updateMenu = { ...updateMenu, path: menu.path }
				}

				if (find.order !== menu.order) {
					updateMenu = { ...updateMenu, order: menu.order }
				}

				if (find.level !== menu.level) {
					updateMenu = { ...updateMenu, level: menu.level }
				}

				if (find.parent_menuid !== menu.parent_menuid) {
					updateMenu = { ...updateMenu, parent_menuid: menu.parent_menuid }
				}
				updateMenus.push(updateMenu)
			}
		})
		// mainMenus.map((menu, index) => {
		// 	const find = originalMainMenus.find((originMenu) => originMenu.id === menu.id)

		// 	if (!find) {
		// 		// newMenus.push(menu)
		// 	} else {
		// 		const menu1 = JSON.stringify(menu)
		// 		const menu2 = JSON.stringify(find)

		// 		if (menu1 === menu2) {
		// 			return
		// 		}

		// 		let updateMenu: any = {}
		// 		updateMenu = {id : menu.id, name: menu.name}

		// 		if (find.name !== menu.name) {
		// 			updateMenu = {...updateMenu, name: menu.name}
		// 		}

		// 		if (find.path !== menu.path) {
		// 			updateMenu = {...updateMenu, path: menu.path}
		// 		}

		// 		if (find.order !== menu.order) {
		// 			updateMenu = {...updateMenu, order: menu.order}
		// 		}

		// 		if (find.level !== menu.level) {
		// 			updateMenu = {...updateMenu, level: menu.level}
		// 		}

		// 		if (find.parnet_menuid !== menu.parnet_menuid) {
		// 			updateMenu = {...updateMenu, parnet_menuid: menu.parnet_menuid}
		// 		}
		// 		updateMenus.push(updateMenu)
		// 	}
		// })

		// subMenus.map((menu, index) => {
		// 	const find = originalSubnMenus.find((originMenu) => originMenu.id === menu.id)

		// 	if (!find) {
		// 		// newMenus.push(menu)
		// 	} else {
		// 		const menu1 = JSON.stringify({id: menu.id, name: menu.name, path: menu.path, order: menu.order, level: menu.level, parent_menuid: menu.parent_menuid})
		// 		const menu2 = JSON.stringify(find)

		// 		if (menu1 === menu2) {
		// 			return
		// 		}

		// 		let updateMenu: any = {}
		// 		updateMenu = {id : menu.id, name: menu.name}

		// 		if (find.name !== menu.name) {
		// 			updateMenu = {...updateMenu, name: menu.name}
		// 		}

		// 		if (find.path !== menu.path) {
		// 			updateMenu = {...updateMenu, path: menu.path}
		// 		}

		// 		if (find.order !== menu.order) {
		// 			updateMenu = {...updateMenu, order: menu.order}
		// 		}

		// 		if (find.level !== menu.level) {
		// 			updateMenu = {...updateMenu, level: menu.level}
		// 		}

		// 		if (find.parnet_menuid !== menu.parnet_menuid) {
		// 			updateMenu = {...updateMenu, parnet_menuid: menu.parnet_menuid}
		// 		}
		// 		updateMenus.push(updateMenu)
		// 	}
		// })

		return updateMenus
	}

	const updateMenuData = () => {
		if (
			clickedMenu?.name !== name ||
			clickedMenu?.path !== path ||
			clickedMenu?.parent_menuid !== parent
		)
			setEdit(true)
		else setEdit(false)
	}

	const tempUpdate = () => {
		const result = validateUpdateMenu()

		if (!result) {
			return
		}

		if (!name || name.length > 20) {
			antd.message.error('메뉴명은 20자 이하로 작성해주세요')
			return
		}

		if (!CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 20자 이하의 영문, 숫자로 작성해주세요')
			return
		}

		const { order, level } = result

		let temp: any = []
		level === 1 ? (temp = [...mainMenus]) : (temp = [...subMenus])

		let newMenu: any = {}

		if (clickedMenu?.parent_menuid === parent) {
			const index = temp.indexOf(clickedMenu)
			temp[index].name = name
			temp[index].path = path
			level === 1 ? setMainMenus(temp) : setSubMenus(temp)
		} else if (clickedMenu?.level === level) {
			newMenu = {
				id: clickedMenu?.id,
				order: order,
				level: level,
				name: name,
				path: path,
				parent_menuid: parent ? parent : null,
			}
			temp.push(newMenu)
			sliceMenu(clickedMenu, temp)
			// setSubMenus(temp)
			// sliceMenu(clickedMenu, temp)
		} else {
			if (level === 1) {
				newMenu = {
					id: clickedMenu?.id,
					level: level,
					order: order,
					name: name,
					path: path,
					parent_menuid: parent ? parent : null,
				}
				temp = [...mainMenus, newMenu]
				setMainMenus(temp)
			} else {
				newMenu = {
					id: clickedMenu?.id,
					order: order,
					level: level,
					name: name,
					path: path,
					parent_menuid: parent ? parent : null,
				}
				temp = [...subMenus, newMenu]
				setSubMenus(temp)
				// sliceMenu(clickedMenu, temp)
			}
			sliceMenu(clickedMenu)
		}
		// if (clickedMenu?.level !== level) {
		// 	newMenu = { id: clickedMenu?.id, lastOrder: order, level: level, name: name, path: path, parent_menuid: parent ? parent : null }
		// 	temp.push(newMenu)
		// 	sliceMenu(clickedMenu, temp)
		// }
		setEdit(false)
		setClickedMenu(newMenu)
	}

	const settingParentMenu = (parentid: string) => {
		setParent(Number(parentid))
	}

	useEffect(() => {
		updateMenuData()
	}, [name, path, parent])

	useEffect(() => {
		setName(clickedMenu?.name)
		setPath(clickedMenu?.path)
		setParent(clickedMenu?.parent_menuid)
	}, [clickedMenu])

	return (
		<div style={{ width: 800, maxWidth: '100%', overflowX: 'auto', background: '#fff' }}>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>메뉴 설정</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 시스템 &gt; 메뉴설정</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<UpdateButton onClick={() => setCreateModal(true)}>+ 추가</UpdateButton>
					<SDeleteButton onClick={deleteMenu}>삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<Content
				style={{
					maxWidth: '100%',
					flexDirection: width < 1000 ? 'column' : 'row',
				}}>
				<SectionLeft>
					<MenuList>
						<Header>카테고리</Header>
						<List>
							{mainMenus?.map((menu, index) => {
								return (
									<View
										key={index.toString()}
										style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
										{clickedMenu?.id === menu?.id ? (
											<>
												<ClickedMenuItem onClick={() => setClickedMenu(menu)}>
													{menu.name}
												</ClickedMenuItem>
												{subMenus.map((submenu, index) => {
													if (submenu.parent_menuid === menu.id) {
														if (clickedMenu?.id === submenu?.id) {
															return (
																<ClickedSubMenuItem
																	key={index.toString()}
																	onClick={() => setClickedMenu(submenu)}>
																	<img src={downleft} alt="asd" />
																	{submenu.name}
																</ClickedSubMenuItem>
															)
														} else {
															return (
																<SubMenuItem
																	key={index.toString()}
																	onClick={() => setClickedMenu(submenu)}>
																	<img src={downleft} alt="asd" />
																	{submenu.name}
																</SubMenuItem>
															)
														}
													}
												})}
											</>
										) : (
											<>
												<MenuItem onClick={() => setClickedMenu(menu)}>{menu.name}</MenuItem>
												{subMenus.map((submenu, index) => {
													if (submenu.parent_menuid === menu.id) {
														if (clickedMenu?.id === submenu?.id) {
															return (
																<ClickedSubMenuItem
																	key={index.toString()}
																	onClick={() => setClickedMenu(submenu)}>
																	<img src={downleft} alt="asd" />
																	{submenu.name}
																</ClickedSubMenuItem>
															)
														} else {
															return (
																<SubMenuItem
																	key={index.toString()}
																	onClick={() => setClickedMenu(submenu)}>
																	<img src={downleft} alt="asd" />
																	{submenu.name}
																</SubMenuItem>
															)
														}
													}
												})}
											</>
										)}
									</View>
								)
							})}
						</List>
						<ArrowButtonWrap>
							{/* <img src={topicon} onClick={()=>move('top')}/> */}
							<img src={upicon} onClick={() => move('up')} alt="asd" />
							<img src={downicon} onClick={() => move('down')} alt="asd" />
							{/* <img src={bottomicon} onClick={()=>move('bottom')}/> */}
						</ArrowButtonWrap>
					</MenuList>
				</SectionLeft>
				<SectionRight style={{ width: '100%' }}>
					{clickedMenu && (
						<>
							<MenuForm style={{ width: '100%' }}>
								<MenuInfoWrap>
									<FormItemTitle>상위메뉴</FormItemTitle>
									<Select onChange={e => settingParentMenu(e.target.value)}>
										<option value={0}>없음</option>
										{mainMenus.map((menu, index) => {
											if (menu.id != 45)
												return (
													<option
														key={index.toString()}
														value={menu.id}
														selected={parent ? (parent === menu.id ? true : false) : false}>
														{menu.name}
													</option>
												)
										})}
									</Select>
								</MenuInfoWrap>
								<MenuInfoWrap>
									<FormItemTitle>카테고리명</FormItemTitle>
									<Input
										type="text"
										name="name"
										onChange={e => setName(e.target.value)}
										value={name}></Input>
								</MenuInfoWrap>
								<MenuInfoWrap>
									<FormItemTitle>경로</FormItemTitle>
									<Input
										type="text"
										name="path"
										onChange={e => setPath(e.target.value)}
										value={path}></Input>
								</MenuInfoWrap>
							</MenuForm>
							{edit && (
								<ButtonWrap>
									<CancelButton onClick={tempUpdate}>적용</CancelButton>
								</ButtonWrap>
							)}
						</>
					)}
				</SectionRight>
			</Content>
			{/* <BottomLine></BottomLine> */}
			<ButtonWrap>
				<CreateButton onClick={update}>저장</CreateButton>
			</ButtonWrap>
			{createModal && (
				<CreateForm
					showModal={createModal}
					handleModal={() => setCreateModal(false)}
					menus={originalMainMenus}
					refreshList={getMenus}
				/>
			)}
		</div>
	)
}

export default NewMenuManagementTable
