import React, { useState, useEffect } from 'react'
import * as antd from 'antd'
import moment from 'moment'

import { useToken } from 'app/hooks/'
import API from 'utils/api'

import {
	TableListSection,
	ContentWrap,
	DataRow,
	TableTitle,
	Date,
	ContentDataRow,
	Content,
	BackToListButton,
} from './styles'
import { ButtonWrap, TableWrap } from 'styles/globalPage-styles'

import Editor from './editor'
import LockModal from './LockModal'
import { useHistory } from 'react-router-dom'

const PostManagementTable = () => {
	const { data: token } = useToken()

	const [post, setPost] = useState<any>()

	const [boardid, setBoardid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[1]?.split('?')[0]) || 0
	)
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0
	)
	const [edit, setEdit] = useState(false)

	const [content, setContent] = useState('')

	const [title, setTitle] = useState('')
	const [writer, setWriter] = useState('')

	const [lock, setLock] = useState(false)
	const [password, setPassword] = useState<string | null>(null)
	const [boardname, setBoardname] = useState('')
	const [postLock, setPostLock] = useState(false)
	const [postPassword, setPostPassword] = useState('')
	const navigate = useHistory()

	const initData = () => {
		if (post) {
			setContent(post.content)
			setTitle(post.title)
			setLock(post.lock)
			setPassword(post.password)
			setWriter(post.writer)
		}
	}

	const handleModal = () => {
		setPostLock(false)
	}

	const getPost = () => {
		setBoardid(parseInt(window.location.pathname?.split('/')?.[1]?.split('?')[0]) || 0)
		setPostid(parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0)

		const name = decodeURI(decodeURIComponent(window.location.search?.split('=')[1]))
		setBoardname(name)
		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}/${postid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					if (res.result.result.lock) {
						setPostPassword(res.result.result.password)
					}
					setContent(res.result.result.content)
					setTitle(res.result.result.title)
					setPost(res.result.result)
					setLock(res.result.result.lock)
					setPassword(res.result.result.password)
					setWriter(res.result.result.writer)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const validateData = () => {
		let data: any = {}

		if (post.content != content) {
			data = {
				...data,
				content,
			}
		}

		if (post.title != title) {
			data = {
				...data,
				title,
			}
		}

		if (post.lock != lock) {
			data = {
				...data,
				lock,
			}
		}

		if (post.password != password) {
			data = {
				...data,
				password,
			}
		}
		if (Object.keys(data).length < 1) return undefined
		return data
	}

	const updatePost = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}`

		const data: any = validateData()

		if (!data) {
			antd.message.info('수정사항없음')
			return
		}

		API.put(apiurl, token + '', data)
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					let tempPost = { ...post }
					tempPost['content'] = content
					tempPost['title'] = title
					tempPost['password'] = password
					tempPost['lock'] = lock
					setPost(tempPost)
					// setPost(null)
					// setContent(res.result.result.content)
					// setTitle(res.result.result.title)
					// // setPost(res.result.result)
					// setPassword(res.result.result.password)
					// setLock()
					setEdit(false)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const goToList = () => {
		navigate.push(`/notice`)
	}

	useEffect(() => {
		if (postPassword) setPostLock(true)
	}, [postPassword])

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	useEffect(() => {
		if (postid) getPost()
	}, [])

	return (
		<TableListSection>
			<ContentWrap>
				<TableWrap>
					<DataRow>
						{/* <TitleHeader>제목</TitleHeader> */}
						<TableTitle>
							{!edit ? (
								decodeURIComponent(title)
							) : (
								<antd.Input value={title} onChange={e => setTitle(e.target.value)}></antd.Input>
							)}
						</TableTitle>
					</DataRow>
					<DataRow>
						{/* <TitleHeader>제목</TitleHeader> */}
						<Date>
							{!edit ? (
								moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')
							) : (
								<antd.Input value={title} onChange={e => setTitle(e.target.value)}></antd.Input>
							)}
						</Date>
					</DataRow>
					{/* <DataRow>
						<TitleHeader>작성자</TitleHeader>
						<Title>
							{
								!edit
								?writer
								:<antd.Input value={writer} onChange={(e)=>setTitle(e.target.value)}></antd.Input>
							}
						</Title>
					</DataRow> */}
					<ContentDataRow>
						{/* <ContentHeader>내용</ContentHeader> */}
						{!edit ? (
							<Content dangerouslySetInnerHTML={{ __html: decodeURIComponent(content) }}></Content>
						) : (
							<Content>
								<Editor content={content} edit={edit} setContent={setContent} />
							</Content>
						)}
					</ContentDataRow>
					{/* <DataRow>
						<ContentHeader>내용</ContentHeader>
						<Content>
							{
								!edit
								? content
								: <ContentTextarea value={content} onChange={(e)=>setContent(e.target.value)}></ContentTextarea>
							}
						</Content>
					</DataRow> */}
				</TableWrap>
				<ButtonWrap style={{ margin: '0 auto' }}>
					<BackToListButton onClick={goToList}>목록보기</BackToListButton>
					{/* {
					!edit
					? <CreateButton onClick={()=>setEdit(true)}>수정</CreateButton>
					:<>
						<CreateButton onClick={updatePost}>저장</CreateButton>
						<CancelButton onClick={()=>setEdit(false)}>취소</CancelButton>		
					</>
				} */}
				</ButtonWrap>
				{postLock && <LockModal postPassword={postPassword} handleModal={handleModal} />}
			</ContentWrap>
			{/* <TableContainer
				className="ag-theme-alpine"
				style={{
					width: '100%',
					height: height - 150,
				}}>
					
						<div style={{
							backgroundColor: 'white',
						}}>					
							<input type='text' style={{ resize: 'none', padding: "10px", width: '100%', border: 'none', height: 'auto' }} value={title} onChange={(e)=>setTitle(e.target.value)} readOnly={!edit}></input>
							<div style={{ display: 'flex' }}>
								<div style={{ paddingLeft: "10px"}}>{post?.created_at}</div>
								<div>{post?.userid}</div>
							</div>
							<textarea style={{ resize: 'none', padding: "10px", width: '100%', height: "500px", border: 'none' }} value={content} onChange={(e)=>setContent(e.target.value)} readOnly={!edit}></textarea>	
						</div>

					{
						edit 
						?				
						<>
						<label style={{ color: "white" }}>잠금</label>
						<input type='checkbox' checked={lock} onChange={(e)=>{setLock(e.target.checked); if (!e.target.checked) {setPassword(null)}}}></input>
						<label>비밀번호</label>
						<input style={{backgroundColor:lock ? "white" :  "gray"}} type='text' value={password ? password : ''} readOnly={!lock} placeholder={!lock ?'잠금시 비밀번호 작성' : ''} onChange={(e)=>setPassword(e.target.value)}></input>
						<div>
								<button onClick={updatePost}>완료</button>
								<button onClick={()=>setEdit(false)}>취소</button>		
							</div>
						</>	
						: <button onClick={()=>setEdit(true)}>수정</button>
					}
			</TableContainer> */}
		</TableListSection>
	)
}

export default PostManagementTable
