import styled from 'styled-components'

export const RowWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	width: 380px;
	height: 50px;
	line-height: 50px;
	background: #ffffff;
`

export const ContentWrap = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	width: 380px;
`

export const ContentColumn1Title = styled.div`
	box-sizing: border-box;
	text-align: center;
	width: 100px;
	height: 35px;
	line-height: 35px;
	background: #fafafa;
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	color: #999999;
`

export const ContentColumn2Title = styled.div`
	box-sizing: border-box;
	width: 280px;
	height: 35px;
	text-align: center;
	background: #fafafa;
	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	color: #999999;
`

export const ContentColumn1 = styled.div`
	box-sizing: border-box;
	width: 100px;
	height: 50px;
	text-align: center;
	background: #ffffff;
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	color: #666666;
`

export const ContentColumn2 = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px 12px;
	width: 280px;
	height: 50px;
	background: #ffffff;
	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const CancelButton = styled.button`
	padding: 0px 10px;
	width: 66px;
	text-align: center;
	height: 30px;
	border: none;
	background: #d44242;
	border-radius: 2px;
	border-color: #d44242;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	color: #ffffff;
`

export const Select = styled.select`
	width: 360px;
	border: 1px solid #cccccc;
	border-radius: 2px;
	padding: 5px 10px;
`

export const Input = styled.input`
	width: 360px;
	border: 1px solid #cccccc;
	border-radius: 2px;
	padding: 5px 10px;
`
export const MenuInfoWrap = styled.div``

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-bottom: 8px;
	gap: 36px;

	/* border-bottom: 1px solid black; */

	width: 974px;
	/* height: 538px; */
`

export const SectionLeft = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;

	/* width: 50%; */
	height: 538px;

	/* stroke */

	border: 1px solid #cccccc;
`

export const MenuList = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;
	/* gap: 12px; */

	overflow-y: hidden;
	overflow-x: hidden;
	width: 360px;
	height: 538px;

	/* stroke */

	/* border: 1px solid #CCCCCC; */
`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 0px;

	width: 360px;
	height: 46px;

	font-size: 15px;
	/* bg/primary */

	color: #ffffff;
	/* font-weight: bold; */

	background: #2e3031;
	border: 1px solid #2e3031;

	margin-bottom: 12px;
`

export const List = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;

	overflow-y: auto;
	overflow-x: hidden;

	width: 360px;
	height: 480px;
`

export const MenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 30px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */

	color: #333333;
`

export const ClickedMenuItem = styled.div`
	background-color: #d5ecf6;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 30px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */
`

export const SubMenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 0px 8px 40px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */

	color: #333333;
`

export const ClickedSubMenuItem = styled.div`
	background-color: #d5ecf6;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 0px 8px 40px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */
`

export const ArrowButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 12px 16px;
	gap: 6px;

	width: 360px;
	height: 48px;
`

export const SectionRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* padding: 12px 0px; */

	/* width: 578px; */
`

export const MenuForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;

	gap: 20px;

	width: 578px;
`

export const FormItemTitle = styled.div`
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;

	/* text/primary */

	color: #333333;

	width: 360px;
`
