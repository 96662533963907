import React, { useEffect, useState } from 'react'
import API from 'utils/api'
import './custom.css'
import CustomLogo from './CustomLogo'
import Footer from './Footer'
import Menu from './Menu'
import CustomInfo from './CustomInfo'
import MobileInfo from './mobile/Info'
import MobileHeader from './mobile/MobileHeader'
import {
	ContetnWrap,
	CustomPageWrap,
	Header,
	HeaderContainer,
	WhiteHeaderContainer,
} from './styles'

import Notice from './Community/Notice'

import MobileFooter from './mobile/Footer'
import BoardWidget from './Community/Board/BoardWidget'
import { Carousel } from 'antd'

import SliderComponent from 'app/components/SliderComponent'
import CustomNews from './CustomNews'

const CustomPage = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [customDatas, setCustomDatas] = useState<any>([])
	const [customDatasForMobile, setCustomDatasForMobile] = useState<any>([])
	const [customDatasForWeb, setCustomDatasForWeb] = useState<any>([])

	const [path, setPath] = useState('')
	const [headerColor, setHeaderColor] = useState(false)
	const [menuHover, setMenuHover] = useState(false)
	const [view, setView] = useState('web')

	const handleResize = () => {
		setWindowWidth(window.innerWidth)
	}

	const setCustomDatasByLayout = (dats: any[]) => {
		let webfilter = dats.filter(r => r.layout == 'web')
		let mobilefilter = dats.filter(r => r.layout == 'mobile')

		if (webfilter.length < 1) {
			webfilter = mobilefilter
		}

		if (mobilefilter.length < 1) {
			mobilefilter = webfilter
		}

		setCustomDatasForWeb(webfilter)
		setCustomDatasForMobile(mobilefilter)

		if (windowWidth > 1000) {
			setCustomDatas(webfilter)
		} else {
			setCustomDatas(mobilefilter)
		}
	}

	useEffect(() => {
		if (view == 'web') {
			setCustomDatas(customDatasForWeb)
		} else {
			setCustomDatas(customDatasForMobile)
		}
	}, [view])

	useEffect(() => {
		console.log(windowWidth)
		if (view == 'web' && windowWidth < 1000) {
			setView('mobile')
		} else if (view == 'mobile' && windowWidth > 1000) {
			setView('web')
		}
	}, [windowWidth])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const getContent = (url: string = 'home') => {
		API.get(`/custom/pages/index/${url}`, null, {}).then(res => {
			if (res?.result?.length > 0) {
				setCustomDatasByLayout(res.result)
			} else {
				setCustomDatas([])
			}
		})
	}

	useEffect(() => {
		const url = window.location.pathname?.split('/')?.[1]?.split('?')[0]

		if (!url) {
			setPath('home')
			setHeaderColor(false)
			getContent('home')
		} else {
			setPath(url)
			setHeaderColor(true)
			getContent(url)
		}
	}, [window.location.pathname])

	return (
		<CustomPageWrap>
			{windowWidth > 1000 ? (
				<>
					{!headerColor ? (
						<HeaderContainer
							onMouseEnter={() => setMenuHover(true)}
							onMouseLeave={() => setMenuHover(false)}>
							<Header>
								<CustomLogo menuHover={menuHover} />
								<Menu path={path} setPath={setPath} />
							</Header>
						</HeaderContainer>
					) : (
						<WhiteHeaderContainer>
							<Header>
								<CustomLogo menuHover={menuHover} />
								<Menu path={path} setPath={setPath} />
							</Header>
						</WhiteHeaderContainer>
					)}
				</>
			) : (
				<MobileHeader />
			)}
			{customDatas?.length > 0 &&
				customDatas?.map((customData, index) => {
					if (!customData?.content) return null
					else {
						if (path === 'home' && index == 0) {
							return (
								<ContetnWrap
									width={`${customData?.width}${customData?.widthunit}`}
									height={`${customData?.height}${customData?.heightunit}`}
									dangerouslySetInnerHTML={{
										__html: decodeURIComponent(customData?.content),
									}}></ContetnWrap>
							)
						} else if (windowWidth > 1000 && path === 'home' && index == 3) {
							return <CustomNews />
						} else if (windowWidth <= 1000 && path === 'home' && index == customDatas?.length - 1) {
							return (
								<a href="mailto:admin@green.co.kr">
									<ContetnWrap
										width={`${customData?.width}${customData?.widthunit}`}
										height={`${customData?.height}${customData?.heightunit}`}
										dangerouslySetInnerHTML={{
											__html: decodeURIComponent(customData?.content),
										}}></ContetnWrap>
								</a>
							)
						} else {
							let notice = false
							let data = decodeURIComponent(customData?.content)
							if (data.includes('[board:notice]')) {
								notice = true
							}

							data = data.replace(/\[board\:notice\]/g, '')

							let slider = false

							if (data.includes('[slider]')) {
								slider = true
							}
							const input = data
							const regex = /\[slider\]([\s\S]*?)\[\/slider\]/
							const sliderResult: any = regex.exec(input)
							const sliderContent = sliderResult?.[1]

							const imgRegex = /<img[^>]+src="([^">]+)"/g
							data = data.replace(/\[slider\]([\s\S]*?)\[\/slider\]/g, '')
							let imgResult: any
							const imgSources: any = []
							while ((imgResult = imgRegex?.exec(sliderContent)) !== null) {
								if (imgResult[1]) {
									imgSources.push(imgResult[1])
								}
							}

							// console.log(imgSources) // ["image1.jpg", "image2.jpg"]
							return (
								<>
									<ContetnWrap
										data-aos-delay="500"
										data-aos-duration="3000"
										width={`${customData?.width}${customData?.widthunit}`}
										height={`${customData?.height}${customData?.heightunit}`}
										style={{ margin: notice ? '0 auto' : '0 auto 100px auto' }}
										dangerouslySetInnerHTML={{
											__html: data,
										}}></ContetnWrap>
									{notice && <BoardWidget />}
									{slider && (
										<>
											{windowWidth <= 1000 ? (
												<Carousel effect="fade" style={{ marginTop: -50, marginBottom: 50 }}>
													{imgSources?.map((img: any) => (
														<div>
															<img
																src={img}
																alt={'...'}
																style={{ width: '100%', maxWidth: 'fit-content' }}
															/>
														</div>
													))}
												</Carousel>
											) : (
												<SliderComponent images={imgSources} />
											)}
										</>
									)}
								</>
							)
						}
					}
				})}
			{path === 'contact' ? windowWidth > 1000 ? <CustomInfo /> : <MobileInfo /> : null}
			{window.location.pathname?.split('/')?.[1]?.split('?')[0] == 'notice' && <Notice />}
			{windowWidth > 1000 ? (
				customDatas[0]?.footer ? (
					<Footer />
				) : null
			) : customDatas[0]?.footer ? (
				<MobileFooter />
			) : null}

			{/* {
				(customDatas?.length > 0 && customDatas[0].footer) &&
				<Footer />
			} */}
			{/* {
				customDatas?.length < 1 &&
				<NotFoundPage />
			} */}
		</CustomPageWrap>
	)
}

export default CustomPage
