/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
	TableWrap,
	ControllerWrap,
	ButtonWrap,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	CreateButton,
	SDeleteButton,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ContentWrap,
	DataRowWrapHeader,
	SelectOption,
	SearchWrap,
	SelectDropdown,
	SearchInput,
	SearchButton,
	NameWrap,
	NicknameWrap,
	EmailWrap,
	PhoneWrap,
	CreatedAtWrap,
	Nocontent,
} from 'styles/globalPage-styles'
import { useToken, useWindowDimensions } from 'app/hooks/'
import API from 'utils/api'
import SignupForm from './SignupForm'
import PaginatedItems from '../../components/Pagination'
import setting from '../../../../../assets/icons/setting.png'
import * as antd from 'antd'

const UserManagementTable = () => {
	const { data: token } = useToken()
	const { width } = useWindowDimensions()
	const [pageSize, setPageSize] = useState<number[]>([])

	const [create, setCreate] = useState(false)
	const [update, setUpdate] = useState(false)
	const [userid, setUserid] = useState(0)
	const [data, setData] = useState<any>()
	const [roles, setRoles] = useState<any>([])
	const [type, setType] = useState('name')
	const [searchWord, setSearchWord] = useState('')
	const [users, setUsers] = useState<any>([])
	const [page, setPage] = useState(1)
	const [checkedList, setCheckedList] = useState<any>([])
	const [change, setChange] = useState(false)

	const changePage = (num: number) => {
		setPage(num)
	}

	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/users/${c.id}`

			return API.delete(apiurl, token + '', {})
				.then((res: any) => {})
				.catch((err: any) => {})
		})

		// setCheckedList([])
		setChange(true)
	}

	useEffect(() => {
		if (change) {
			getUsers()
			setChange(false)
		}
	}, [change])

	const deleteUser = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		let apiurl = `/users/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					getUsers()
				}
			})
			.catch((err: any) => {
				antd.message.error('삭제에 실패하였습니다\n' + err)
			})
	}

	const getUser = () => {
		let apiurl = `/users/${userid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setData(res.result.result)
				}
			})
			.catch((err: any) => {})
	}

	const getUsers = () => {
		let apiurl = `/users?type=${type}&searchWord=${searchWord}&maxData=20&page=` + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setRoles(res.result.result.roles)
					setUsers(res.result.result.users)
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
				}
			})
			.catch((err: any) => {
				antd.message.error('회원 목록을 불러오는데 실패하였습니다\n' + err)
			})
	}

	const handleModal = () => {
		create && setCreate(false)

		if (update) {
			setUpdate(false)
			setUserid(0)
		}
	}

	const insertNewUser = () => {
		getUsers()
	}

	const CreateCMP = (
		<SignupForm
			showModal={create}
			handleModal={handleModal}
			roles={roles}
			insertNewUser={insertNewUser}
		/>
	)

	const updateCMP = (
		<SignupForm
			showModal={update}
			handleModal={handleModal}
			roles={roles}
			data={data}
			insertNewUser={insertNewUser}
		/>
	)

	useEffect(() => {
		if (data) {
			setUpdate(true)
		}
	}, [data])

	useEffect(() => {
		if (userid) {
			getUser()
		}
	}, [userid])

	useEffect(() => {
		if (!page) setPage(1)
		else getUsers()
	}, [page])

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>회원관리</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 회원관리</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap
				style={{
					paddingTop: 10,
					flexDirection: width < 762 ? 'column-reverse' : 'row',
					padding: 5,
				}}>
				<ButtonWrap style={{ marginTop: width < 762 ? 10 : 0 }}>
					<CreateButton onClick={() => setCreate(true)}>등록</CreateButton>
					<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
				</ButtonWrap>
				<SearchWrap>
					<SelectDropdown onChange={e => setType(e.target.value)}>
						<SelectOption value={'name'} defaultValue={'name'}>
							이름
						</SelectOption>
						<SelectOption value={'nickname'}>닉네임</SelectOption>
						<SelectOption value={'email'}>이메일</SelectOption>
					</SelectDropdown>
					<SearchInput
						placeholder="검색어를 입력하세요"
						type="text"
						onChange={e => setSearchWord(e.target.value)}></SearchInput>
					<SearchButton onClick={() => setPage(0)}>검색</SearchButton>
				</SearchWrap>
			</ControllerWrap>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input style={{ width: '12px' }} type="checkbox"></input>
					</CheckboxWrap>
					<NumberWrap>NO.</NumberWrap>
					<NameWrap>이름</NameWrap>
					<NicknameWrap>닉네임</NicknameWrap>
					<EmailWrap>이메일</EmailWrap>
					<PhoneWrap>전화번호</PhoneWrap>
					<CreatedAtWrap>가입일</CreatedAtWrap>
					<DeleteButtonWrap>관리</DeleteButtonWrap>
				</DataRowWrapHeader>
				{users?.map((user, index) => {
					return (
						<DataRowWrap key={index.toString()} onDoubleClick={() => setUserid(user.id)}>
							<CheckboxWrap>
								<input
									style={{ width: '12px' }}
									type="checkbox"
									onChange={e => checked(e.target.checked, user.id)}></input>
							</CheckboxWrap>
							<NumberWrap>{user.id}</NumberWrap>
							<NameWrap>{user.name}</NameWrap>
							<NicknameWrap>{user.nickname}</NicknameWrap>
							<EmailWrap>{user.email}</EmailWrap>
							<PhoneWrap>{user.phone}</PhoneWrap>
							<CreatedAtWrap>
								{moment(user?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
							</CreatedAtWrap>
							<DeleteButtonWrap>
								<DeleteButton onClick={() => deleteUser(user.id)}>삭제</DeleteButton>
							</DeleteButtonWrap>
						</DataRowWrap>
					)
				})}
				{users?.length < 1 && <Nocontent>등록된 사용자가 없습니다</Nocontent>}
				{create && CreateCMP}
				{update && updateCMP}
			</TableWrap>
			<PaginatedItems page={page} pageSize={pageSize} changePage={changePage} itemsPerPage={5} />
		</ContentWrap>
	)
}

export default UserManagementTable
