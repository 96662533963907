import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
	TableWrap,
	ControllerWrap,
	ButtonWrap,
	CreateButton,
	UpdateButton,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButton,
	SearchWrap,
	SelectDropdown,
	SearchInput,
	SearchButton,
	NameWrap,
	NicknameWrap,
	EmailWrap,
	PhoneWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import API from 'utils/api'
import { useToken, useWindowDimensions } from 'app/hooks'
import PaginatedItems from '../../components/Pagination'
import * as antd from 'antd'
import { View } from 'styles/reactnative'
type Props = {
	handleModal: () => void
	receiverList: any[]
	setReceiverList: React.Dispatch<React.SetStateAction<any[]>>
	showModal?: boolean
}

function UserListModal({ showModal, handleModal, receiverList, setReceiverList }: Props) {
	const { data: token } = useToken()
	const { width } = useWindowDimensions()
	const [pageSize, setPageSize] = useState<number[]>([])
	const [type, setType] = useState('name')
	const [searchWord, setSearchWord] = useState('')
	const [users, setUsers] = useState<any>([])
	const [page, setPage] = useState(1)

	const [receivers, setReceivers] = useState<any>([])

	const changePage = (num: number) => {
		setPage(num)
	}

	const getUsers = () => {
		let apiurl = `/users?type=${type}&searchWord=${searchWord}&maxData=20&page=` + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					let temp = [...res.result.result.users]

					receiverList.map(receiver => {
						temp.map(user => {
							if (receiver.id === user.id) {
								user.selected = true
							}
						})
					})

					receivers.map(receiver => {
						temp.map(user => {
							if (receiver.id === user.id) {
								user.selected = true
							}
						})
					})

					setReceiverList([])
					setUsers(temp)
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const allCheck = (checked: boolean) => {
		let tempReceivers = [...receivers]
		let tempUsers = [...users]

		if (checked) {
			tempUsers.map(user => {
				if (user.selected !== true) {
					tempReceivers.push(user)
					user.selected = true
				}
			})
		} else {
			tempReceivers = []
			tempUsers.map(user => {
				user.selected = false
			})
		}

		setReceivers(tempReceivers)
		setUsers(tempUsers)
	}

	const checkedUser = (checked: boolean, user: any) => {
		let tempReceivers = [...receivers]
		let tempUsers = [...users]

		if (checked) {
			tempReceivers.push(user)
			tempUsers.map(u => {
				if (u.id === user.id) {
					u.selected = true
				}
			})
		} else {
			tempReceivers = tempReceivers.filter(r => r.id !== user.id)
			tempUsers.map(u => {
				if (u.id === user.id) {
					u.selected = false
				}
			})
		}

		setReceivers(tempReceivers)
		setUsers(tempUsers)
	}

	const addReceivers = () => {
		if (receivers.length < 1) {
			antd.message.error('수신자를 체크해주세요')
			return
		}

		setReceiverList(receivers)
		handleModal()
	}

	useEffect(() => {
		if (!page) setPage(1)
		else getUsers()
	}, [page])

	useEffect(() => {
		let temp: any = []
		receiverList?.map(re => {
			temp.push(re)
		})

		setReceivers(temp)
	}, [])

	return (
		<antd.Modal
			title={'받는사람 등록'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={742}
			style={{ height: 699 }}>
			<ControllerWrap style={{ border: 'none', flexDirection: width < 600 ? 'column' : 'row' }}>
				<ButtonWrap style={{ marginBottom: width < 600 ? 10 : 0 }}>
					<UpdateButton style={{ width: '60px', fontSize: '12PX' }} onClick={() => allCheck(true)}>
						모두선택
					</UpdateButton>
					<DeleteButton style={{ width: '60px', fontSize: '12PX' }} onClick={() => allCheck(false)}>
						선택해제
					</DeleteButton>
				</ButtonWrap>
				<SearchWrap>
					<SelectDropdown onChange={e => setType(e.target.value)}>
						<option value={'name'} selected>
							이름
						</option>
						<option value={'nickname'}>닉네임</option>
						<option value={'email'}>이메일</option>
					</SelectDropdown>
					<SearchInput
						placeholder="검색어를 입력하세요"
						type="text"
						onChange={e => setSearchWord(e.target.value)}></SearchInput>
					<SearchButton onClick={() => setPage(0)}>검색</SearchButton>
				</SearchWrap>
			</ControllerWrap>
			<List>
				<ListTitle>받는사람</ListTitle>
				<ListContent>
					{receivers?.map(receiver => {
						return (
							<Receiver>
								<Remove onClick={() => checkedUser(false, receiver)}>✖</Remove>
								<span>{receiver.name}</span>
							</Receiver>
						)
					})}
				</ListContent>
			</List>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input type="checkbox"></input>
					</CheckboxWrap>
					<NumberWrap>NO.</NumberWrap>
					<NameWrap>이름</NameWrap>
					<NicknameWrap>닉네임</NicknameWrap>
					<EmailWrap>이메일</EmailWrap>
					<PhoneWrap>전화번호</PhoneWrap>
					<CreatedAtWrap>가입일</CreatedAtWrap>
				</DataRowWrapHeader>
				{users?.map(user => {
					return (
						<DataRowWrap>
							<CheckboxWrap>
								<input
									type="checkbox"
									onChange={e => checkedUser(e.target.checked, user)}
									checked={user?.selected ? true : false}></input>
							</CheckboxWrap>
							<NumberWrap>{user.id}</NumberWrap>
							<NameWrap>{user.name}</NameWrap>
							<NicknameWrap>{user.nickname}</NicknameWrap>
							<EmailWrap>{user.email}</EmailWrap>
							<PhoneWrap>{user.phone}</PhoneWrap>
							<CreatedAtWrap>
								{moment(user?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}
							</CreatedAtWrap>
						</DataRowWrap>
					)
				})}
			</TableWrap>
			<PaginatedItems page={page} pageSize={pageSize} changePage={changePage} itemsPerPage={5} />
			<View style={{ width: '100%' }}>
				<CreateButton onClick={addReceivers} style={{ width: '35%', marginTop: 10 }}>
					확인
				</CreateButton>
			</View>
		</antd.Modal>
	)
}

export default UserListModal

const CreatedAtWrap = styled.div`
	box-sizing: border-box;

	width: 150px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
const List = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 100%;
	height: 100px;

	/* table/border */

	border: 1px solid #e4e4e4;
`
const ListTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	width: 100px;
	height: 100px;
	line-height: 100px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const ListContent = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-flow: row wrap;

	overflow-x: hidden;
	overflow-y: auto;

	padding: 10px;
	width: 100%;
	height: 100px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	/* or 18px */

	/* text/contents */

	color: #666666;
`

const Receiver = styled.div`
	width: auto;
	height: 20px;
	margin-right: 10px;

	&:hover {
		border-bottom: 1px solid gray;
	}
`

const Remove = styled.span`
	margin-right: 5px;

	&:hover {
		cursor: pointer;
		font-size: bold;
	}
`

const ReceiverName = styled.span``
