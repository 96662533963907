import React from 'react'
import KakaoMap from '../KakaoMap'
import { InfoContainer, InfoTextWrap, InfoWrap } from '../styles'
import { Title, Detail, Item, ItemName, ItemColumn } from './styles'
const MobileInfo = () => {
	return (
		<InfoContainer>
			<InfoWrap>
				<KakaoMap />
				<InfoTextWrap>
					<Title>본사</Title>
					<Detail>
						<Item>
							<ItemName>Add</ItemName>
							<ItemColumn>경기도 평택시 중앙2로 13, 602-1-972호(평택동)</ItemColumn>
						</Item>
						<Item>
							<ItemName>E-mail</ItemName>
							<ItemColumn>moandom4@moandom.com</ItemColumn>
						</Item>
					</Detail>
				</InfoTextWrap>
			</InfoWrap>
		</InfoContainer>
	)
}

export default MobileInfo
