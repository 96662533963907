import React, { useEffect, useState } from 'react'
import API from 'utils/api'
import '../../custom.css'
import Logo from '../../CustomLogo'
import Footer from '../../Footer'
import Menu from '../../Menu'
import { useHistory } from 'react-router-dom'
import { CustomPageWrap } from './styles'
import styled from 'styled-components'
import PostManagementTable from '../PostManagement/PostManagementTable'
import MobileHeader from '../../mobile/MobileHeader'
import MobileFooter from '../../mobile/Footer'

const POSTS = [
	{
		id: 1,
		fix: true,
		title: '상단고정되는 공지사항의 제목입니다.',
		created_at: '2023-05-12',
	},
	{
		id: 2,
		fix: true,
		title: '상단고정되는 공지사항의 제목입니다.',
		created_at: '2023-05-12',
	},
	{
		id: 3,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 4,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 5,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 6,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 7,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 8,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
]
function Post() {
	const [path, setPath] = useState('')
	const [pageSize, setPageSize] = useState<number[]>([])
	const [posts, setPosts] = useState<any[]>([])
	const [boardname, setBoardname] = useState('')
	const [page, setPage] = useState(1)
	const navigate = useHistory()
	const [create, setCreate] = useState(false)
	const [headerColor, setHeaderColor] = useState(true)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [customDatas, setCustomDatas] = useState<any>([])
	const [customDatasForMobile, setCustomDatasForMobile] = useState<any>([])
	const [customDatasForWeb, setCustomDatasForWeb] = useState<any>([])
	// const [path, setPath] = useState('')
	const [menuHover, setMenuHover] = useState(false)
	const [view, setView] = useState('web')

	const refreshList = () => {
		getPosts()
	}

	const getPosts = () => {
		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		// let apiurl = `/board/${boardPath}?maxData=20&page=` + 1
		let apiurl = `/board/${boardPath}?maxData=20&page=` + page

		API.get(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
					//더미
					// setPosts(POSTS)
					setPosts(res.result.result.posts)
					setBoardname(res.result.result.boardname)
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const handleModal = (change?: boolean) => {
		if (change) {
			getPosts()
		}
		setCreate(false)
	}

	const deletePost = (id: number) => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		let apiurl = `/board/${boardPath}/${id}`

		API.delete(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const changePage = (num: number) => {
		setPage(num)
	}

	const goToPost = (postid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const name = encodeURI(encodeURIComponent(boardname))
		// navigate.push(`/board/${boardPath}/${postid}?name=${name}`);
		navigate.push(`/notice/${postid}?name=${name}`)
	}

	useEffect(() => {
		getPosts()
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const handleResize = () => {
		setWindowWidth(window.innerWidth)
	}

	const setCustomDatasByLayout = (dats: any[]) => {
		let webfilter = dats.filter(r => r.layout == 'web')
		let mobilefilter = dats.filter(r => r.layout == 'mobile')

		if (webfilter.length < 1) {
			webfilter = mobilefilter
		}

		if (mobilefilter.length < 1) {
			mobilefilter = webfilter
		}

		setCustomDatasForWeb(webfilter)
		setCustomDatasForMobile(mobilefilter)

		if (windowWidth > 1000) {
			setCustomDatas(webfilter)
		} else {
			setCustomDatas(mobilefilter)
		}
	}

	useEffect(() => {
		if (view == 'web') {
			setCustomDatas(customDatasForWeb)
		} else {
			setCustomDatas(customDatasForMobile)
		}
	}, [view])

	useEffect(() => {
		if (view == 'web' && windowWidth < 1000) {
			setView('mobile')
		} else if (view == 'mobile' && windowWidth > 1000) {
			setView('web')
		}
	}, [windowWidth])

	useEffect(() => {
		getContent('notice')
	}, [])

	const getContent = (url: string = 'home') => {
		API.get(`/custom/pages/index/${url}`, null, {}).then(res => {
			if (res?.result?.length > 0) {
				setCustomDatasByLayout(res.result)
			} else {
				setCustomDatas([])
			}
		})
	}
	return (
		<CustomPageWrap>
			{windowWidth > 1000 ? (
				<HeaderContainer
					onMouseEnter={() => setMenuHover(true)}
					onMouseLeave={() => setMenuHover(false)}>
					<Header>
						<Logo menuHover={menuHover} />
						<Menu path={path} setPath={setPath} />
					</Header>
				</HeaderContainer>
			) : (
				<MobileHeader />
			)}
			{customDatas?.length > 0 &&
				customDatas?.map((customData, index) => {
					return (
						<ContetnWrap
							width={`${customData?.width}${customData?.widthunit}`}
							height={`${customData?.height}${customData?.heightunit}`}
							dangerouslySetInnerHTML={{
								__html: decodeURIComponent(customData?.content),
							}}></ContetnWrap>
					)
				})}
			<PostManagementTable />
			{/* <CommentManagementTable /> */}
			{windowWidth > 1000 ? <Footer /> : <MobileFooter />}
		</CustomPageWrap>
	)
}

export default Post

export const ContetnWrap = styled.div<{ width: string; height: string }>`
	width: ${props => props.width};
	/* height:${props => props.height};*/
	height: auto;
	margin: 0 auto;
	/* margin: 0 auto 300px auto;	 */
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		margin: 0 auto 100px auto;
	}

	@media screen and (max-width: 570px) {
		margin: 0 auto 50px auto;
	}
`

const Header = styled.div`
	position: absolute;
	width: 100%;
	max-width: 1320px;
	display: flex;
	/* justify-content: space-evenly; */
	padding: 10px;
	align-items: center;
	justify-content: space-between;
`

export const TitleWrapLeft = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
	height: 29px;

	@media only screen and (max-width: 1000px) {
		display: none;
	}
`

export const TitleWrapRight = styled.div`
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-size: 13px;

	display: flex;
	gap: 5px;
	/* identical to box height */

	/* text/contents */

	color: #666666;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

	@media only screen and (max-width: 1000px) {
		/* width: 100%; */
		width: 300px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
`

export const TableWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 1360px;
	margin-bottom: 16px;
	/* table/border */
`

export const Title = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	padding: 0px;
	gap: 10px;

	width: 100%;
	height: 26px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	/* font-weight: 700; */
	font-size: 20px;
	line-height: 26px;

	text-align: left;

	&:hover {
		cursor: pointer;
	}
`

export const Date = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 167px;
	height: 36px;

	text-align: center;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	/* font-weight: 700; */
	font-size: 16px;
	line-height: 26px;
	display: flex;
`

const HeaderContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 80px;
	top: 0;
	display: flex;
	/* justify-content: space-evenly; */
	padding: 10px;
	background-color: #ffffff;
	align-items: center;
	justify-content: center;
`
