import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'utils/api'

import news from 'assets/images/news.png'
import plus from 'assets/icons/plus.png'
import notice1 from 'assets/images/notice1.png'
import notice2 from 'assets/images/notice2.png'
import notice3 from 'assets/images/notice3.png'
import { NewsWrap, Plus } from '../styles'

const NOTICE_IMAGE = [notice1, notice2, notice3]

const CustomNews = () => {
	const navigate = useHistory()
	const [posts, setPosts] = useState<any[]>([])

	const getPosts = (pageNum?: number) => {
		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		// let apiurl = `/board/${boardPath}?maxData=20&page=` + 1
		let apiurl = `/board/${boardPath}?maxData=3&page=` + 1

		API.get(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					setPosts(res.result.result.posts)
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const goToPost = (postid: number) => {
		//const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const name = encodeURI(encodeURIComponent('notice'))
		// navigate.push(`/board/${boardPath}/${postid}?name=${name}`);
		navigate.push(`/notice/${postid}?name=${name}`)
	}

	useEffect(() => {
		getPosts()
	}, [])

	return (
		<div style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'flex-start' }}>
			<div
				style={{ display: 'flex', height: '522px', flexDirection: 'column', alignItems: 'center' }}>
				<div style={{marginLeft: 200}}>
					<img src={news} alt={'...'} />
				</div>
				<Plus
					onClick={() => {
						navigate.push('/notice')
					}}>
				</Plus>
			</div>
			<div
				style={{
					padding: '120px 0',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}>
				{posts?.map((post, index) => {
					return (
						<NewsWrap
							data-aos="fade-up"
							data-aos-anchor-placement="right-center"
							onClick={() => {
								goToPost(post?.id)
							}}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									alignSelf: 'center',
								}}>
								<img src={NOTICE_IMAGE[index]} alt={'...'} />
							</div>
							<div
								style={{
									width: '800px',
									marginLeft: '20px',
									padding: '15px 0',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}>
								<div>
									<p style={{ color: '#1443a8', fontWeight: 'bold', marginBottom: '10px' }}>
										공지사항
									</p>
									<p>{decodeURIComponent(post?.title)}</p>
								</div>
								<div style={{ color: '#A1A1A1' }}>
									{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}
								</div>
							</div>
						</NewsWrap>
					)
				})}
			</div>
		</div>
	)
}
export default CustomNews
