import React from 'react'
import moment from 'moment'
import { Name, Type, Date, Count } from './styles'
import {
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
} from 'styles/globalPage-styles'

const BoardManageItems = ({ item, checked, deleteBoard, setData }) => {
	return (
		<DataRowWrap
			onDoubleClick={() => {
				setData(item)
			}}>
			<CheckboxWrap>
				<input type="checkbox" onChange={e => checked(e.target.checked, item.id)}></input>
			</CheckboxWrap>
			<NumberWrap>{item?.id}</NumberWrap>
			<Name>{item?.name}</Name>
			<Type>{item?.type}</Type>
			<Date>{moment(item?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</Date>
			<Count>{item?.total}</Count>
			<DeleteButtonWrap>
				<DeleteButton onClick={() => deleteBoard(item?.id)}>삭제</DeleteButton>
			</DeleteButtonWrap>
		</DataRowWrap>
	)
}

export default BoardManageItems
