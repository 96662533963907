import React, { useEffect, useState } from 'react'
import API from 'utils/api'
import { Nocontent } from 'styles/globalPage-styles'
import '../../custom.css'

import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PostForm from './PostForm'
import { ContentWrap, Date } from './styles'
import { TextWrap, TableWrap, DataRowWrap, FixedPost } from './styles'
const POSTS = [
	{
		id: 1,
		fix: true,
		title: '상단고정되는 공지사항의 제목입니다.',
		created_at: '2023-05-12',
	},
	{
		id: 2,
		fix: true,
		title: '상단고정되는 공지사항의 제목입니다.',
		created_at: '2023-05-12',
	},
	{
		id: 3,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 4,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 5,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 6,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 7,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
	{
		id: 8,
		fix: false,
		title: '공지사항의 제목을 적어주세요.',
		created_at: '2023-05-12',
	},
]
function BoardWidget() {
	const [pageSize, setPageSize] = useState<number[]>([])
	const [posts, setPosts] = useState<any[]>([])
	const [boardname, setBoardname] = useState('')
	const [page, setPage] = useState(1)
	const navigate = useHistory()
	const [create, setCreate] = useState(false)

	const refreshList = () => {
		getPosts()
	}

	const getPosts = () => {
		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		// let apiurl = `/board/${boardPath}?maxData=20&page=` + 1
		let apiurl = `/board/${boardPath}?maxData=3&page=` + page

		API.get(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
					//더미
					// setPosts(POSTS)
					setPosts(res.result.result.posts)
					setBoardname(res.result.result.boardname)
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const handleModal = (change?: boolean) => {
		if (change) {
			getPosts()
		}
		setCreate(false)
	}

	const deletePost = (id: number) => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		let apiurl = `/board/${boardPath}/${id}`

		API.delete(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const changePage = (num: number) => {
		setPage(num)
	}

	const goToPost = (postid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const name = encodeURI(encodeURIComponent(boardname))
		// navigate.push(`/board/${boardPath}/${postid}?name=${name}`);
		navigate.push(`/notice/${postid}?name=${name}`)
	}

	useEffect(() => {
		getPosts()
	}, [])

	return (
		<>
			{!create ? (
				<ContentWrap style={{ marginBottom: 50, marginTop: -80 }}>
					<TableWrap>
						{posts?.map((post, index) => {
							return (
								<DataRowWrap data-aos="fade-up">
									<TextWrap>
										<FixedPost
											onClick={() => {
												goToPost(post?.id)
											}}>
											{post?.title}
										</FixedPost>
									</TextWrap>
									<Date
										style={{
											border: "none",
											textAlign: "left",
											padding: "0"
										}}>
										{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}
									</Date>
								</DataRowWrap>
							)
						})}
						{posts?.length < 1 && <Nocontent>등록된 게시물이 없습니다</Nocontent>}
					</TableWrap>
				</ContentWrap>
			) : (
				<PostForm handleModal={handleModal} boardname={boardname} />
			)}
		</>
	)
}

export default BoardWidget
