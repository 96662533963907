import { COLORS } from 'app/constants'
import styled from 'styled-components'

export const SliderComponentSection = styled.div`
	flex-direction: row;
	width: 81%;
	height: 400px;
	display: flex;
	/* margin-top: 500px; */
	align-items: center;
	justify-content: center;
	align-self: center;
	margin: auto;
	@media only screen and (max-width: 980px) {
		flex-direction: column;
		width: 100%;
	}
`
export const SliderLeftSection = styled.div`
	width: 22%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding-inline: 10px;
	@media only screen and (max-width: 980px) {
		width: 90%;
		flex-direction: row;
	}
`
export const SliderRightSection = styled.div`
	width: 78%;
	height: 100%;
	display: flex;
	flex-direction: row;
	@media only screen and (max-width: 980px) {
		width: 90%;
	}
`
export const TraderSliderSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`
export const TraderSliderItemSection = styled.div`
	height: 364px;
	width: 100%;
	margin: 5px;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	background-color: ${p => p.theme.COLORS.bgColor};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 6px 0 rgba(50, 50, 50, 0.1);
	transition: 0.3s;
	cursor: pointer;
	&:hover {
		box-shadow: 2px 3px 20px ${COLORS.primaryDark};
	}
`

export const TraderSliderHeader = styled.div`
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: flex-end;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
`
export const TraderSliderHeaderText = styled.div`
	font-size: 20px;
	width: 25%;
	height: 30%;
	background-color: rgba(234, 158, 4, 0.3);
	color: ${p => p.theme.COLORS.textw};
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom-left-radius: 20px;
`
export const TraderSliderBody = styled.div`
	width: 100%;
	height: 70%;
	background-color: ${p => p.theme.COLORS.bgColor};
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
`
