import styled from 'styled-components'

export const CancelButton = styled.button`
	padding: 0px 10px;

	width: 68px;
	text-align: center;
	height: 32px;

	/* content/accentRed */

	background: #d44242;
	border-radius: 2px;
	border: none;

	font-size: 14px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;
`

export const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	text-align: center;
	font-size: 12px;
	background: #ffffff;
`

export const TitleHeader = styled.div`
	box-sizing: border-box;

	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */

	width: 150px;
	height: 46px;
	line-height: 46px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Title = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;

	min-width: 100px;
	width: 824px;
	height: 46px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ContentHeader = styled.div`
	box-sizing: border-box;

	width: 150px;
	height: 400px;
	line-height: 400px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Content = styled.div`
	box-sizing: border-box;

	/* width: 90%; */
	height: 400px;
	padding: 5px;
	width: 824px;
	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Lock = styled.div`
	width: 200px;
	padding: 0 30px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Password = styled.div`
	width: 100%;
	padding: 0 5px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
