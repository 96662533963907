import React, { useState } from 'react'
import { useRef, useEffect } from 'react'
import { MapContainer, ButtonWrap, ClickedButton, Button } from './styles'
import { useWindowDimensions } from 'app/hooks'

declare global {
	interface Window {
		kakao: any
	}
}

const KakaoMap = () => {
	const { width } = useWindowDimensions()
	const { kakao } = window
	const ref = useRef(null)
	const [head, setHead] = useState(true)

	const map = async () => {
		const container = ref.current
		const mapOption = {
			center: new kakao.maps.LatLng(36.99185301800031, 127.08868676227395),
			level: 3,
		}
		const map = new kakao.maps.Map(container, mapOption)

		const markerPosition = new kakao.maps.LatLng(36.99185301800031, 127.08868676227395)
		const marker = new kakao.maps.Marker({
			position: markerPosition,
		})
		marker.setMap(map)
	}

	useEffect(() => {
		map()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const mobileSize = width < 1000

	return (
		<MapContainer>
			<ButtonWrap>
				{head ? (
					<>
						<ClickedButton onClick={() => setHead(true)}>본사</ClickedButton>
						<Button onClick={() => setHead(false)}>지사(예정)</Button>
					</>
				) : (
					<>
						<Button onClick={() => setHead(true)}>본사</Button>
						<ClickedButton onClick={() => setHead(false)}>지사(예정)</ClickedButton>
					</>
				)}
			</ButtonWrap>
			<div
				className="kakao-map-container"
				id="map"
				ref={ref}
				style={{ width: mobileSize ? '100%' : '1360px', height: mobileSize ? '360px' : '680px' }}
			/>
		</MapContainer>
	)
}

export default KakaoMap
