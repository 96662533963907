import styled from 'styled-components'

export const TableListSection = styled.div`
	width: 100%;
	background-color: transparent;
	margin: 100px 0 300px 0;

	@media only screen and (max-width: 1000px) {
		margin: 0;

		/* base/text */
	}
`
export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin: 30px auto;
`
export const ModalWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #f4f7f7;
	z-index: 999;
`
export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;

	position: absolute;
	width: 420px;
	left: 50%;
	top: 15%;
	margin-left: -210px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 26px;

	width: 380px;
`

export const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;

	/* width: 85px; */
	height: 23px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;

	/* text/primary */

	color: #333333;
`
export const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	/* height: 324px; */

	/* table/border */

	/* border: 1px solid #E4E4E4; */
`

export const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	height: 36px;

	background: #ffffff;
`

export const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

export const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	width: 280px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

export const RowDataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;
	line-height: 50px;

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

export const RowDataContent = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	/* flex-direction: column; */
	align-items: flex-start;
	padding: 0px;
	align-items: center;
	justify-content: space-between;
	width: 280px;
	height: 50px;

	padding: 0 5px;
	background: #ffffff;
	/* table/border */

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`

export const BackToListButton = styled.button`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

	width: 173px;
	height: 48px;

	/* base/text */

	border: 1px solid #333333;

	@media only screen and (max-width: 1000px) {
		width: 110px;
		height: 39px;

		font-size: 14px;
		line-height: 19px;

		/* base/text */
	}
`
export const ContentDataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	min-height: 400px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	/* text-align: center; */
	font-size: 12px;
	/* border-width: 0px 1px 0px 1px; */
	border-style: solid;
	border-color: #e4e4e4;
	height: 100%;
	background: #fafafa;
`

export const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	/* text-align: center; */
	font-size: 12px;
	background: #ffffff;

	/* border-width: 1px 1px 1px 1px; */
	border-style: solid;
	border-color: #e4e4e4;
`

export const TableTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;

	min-width: 100px;
	width: 100%;
	height: 46px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

	/* white */

	background: #ffffff;
	/* table/border */

	/* border-width: 0px 0px 0px 1px; */
	border-style: solid;
	border-color: #e4e4e4;

	@media only screen and (max-width: 1000px) {
		font-size: 18px;
	}
`

export const Date = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px 5px 40px 5px;

	min-width: 100px;
	width: 100%;
	height: 46px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

	/* white */

	background: #ffffff;
	/* table/border */

	/* border-width: 0px 0px 0px 1px; */
	border-style: solid;
	border-color: #e4e4e4;

	@media only screen and (max-width: 1000px) {
		font-size: 13px;
	}
`

export const Content = styled.div`
	box-sizing: border-box;
	border-top: 1px solid #f0f0f0;

	/* width: 90%; */

	padding: 40px 5px;
	width: 100%;
	min-height: 560px;
	overflow-y: auto;
	overflow-x: hidden;
	/* white */

	background: #ffffff;
	/* table/border */
	font-size: 16px;
	/* border-width: 0px 0px 0px 1px; */
	border-style: solid;
	border-color: #e4e4e4;

	@media only screen and (max-width: 1000px) {
		font-size: 15px;
	}
`
