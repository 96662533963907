import useSWR from 'swr'
import API from '../../../utils/api'
import { USERINFO } from '../../constants/APIKEYS'
import { useToken } from '../auth/token'
import { getPersistData } from '../persist'

const KEY = USERINFO

let swrData: any = getPersistData(KEY)

export function useMyProfile() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {})
			// console.log(response)

			swrData = response.result.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		data,
		mutate: (value: any) => {
			swrData = value
			return mutate()
		},
	}
}
