import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const AdminDashboardPage = () => {
	const navigate = useHistory()

	useEffect(() => {
		navigate.push('/admin/users')
	}, [])
	return null
}
