import React from 'react'
import { ContentEditor, ContentEditorName, RowData } from '../styles'
import Editor from './PostEditor'
const PostEditor = ({ data, content, edit, setContent }) => {
	return (
		<RowData>
			<ContentEditorName>내용</ContentEditorName>
			{!data ? (
				<ContentEditor>
					<Editor content={content} setContent={setContent}></Editor>
				</ContentEditor>
			) : !edit ? (
				<ContentEditor dangerouslySetInnerHTML={{ __html: content }} />
			) : (
				<ContentEditor>
					<Editor content={content} setContent={setContent}></Editor>
				</ContentEditor>
			)}
		</RowData>
	)
}

export default PostEditor
