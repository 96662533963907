import React, { useState } from 'react'
import { CreateButton } from 'styles/globalPage-styles'
type Props = {
	createComment: (content: string, lock: boolean) => void
}

const CommentForm = ({ createComment }: Props) => {
	const [content, setContent] = useState('')
	const [lock, setLock] = useState(false)

	const create = () => {
		createComment(content, lock)

		setContent('')
	}

	return (
		<div>
			<div
				style={{
					width: '100%',
					marginTop: '20px',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<div style={{ fontSize: '14px' }}> 댓글작성</div>
				<CreateButton onClick={create}>작성</CreateButton>
			</div>
			<textarea
				placeholder="댓글"
				style={{ resize: 'none', width: '100%', border: '1px solid #CCCCCC', borderRadius: '2px' }}
				onChange={e => setContent(e.target.value)}
				value={content}></textarea>
			{/* <label  style={{ color: "white"}} htmlFor="lock">비공개</label>
        <input type="checkbox" id="lock" onChange={(e)=>setLock(e.target.checked)}></input>
        <button onClick={create}>등록</button> */}
		</div>
	)
}

export default CommentForm
