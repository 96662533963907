import React, { useState } from 'react'
import * as antd from 'antd'
import { useHistory } from 'react-router-dom'

import { useToken } from 'app/hooks/'
import API from 'utils/api'
import setting from 'assets/icons/setting.png'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	TableWrap,
	ControllerWrap,
} from 'styles/globalPage-styles'

import Editor from '../PostManagement/editor'
import {
	CancelButton,
	Content,
	DataRow,
	Lock,
	Password,
	PostFormTitle,
	TitleHeader,
	ContentWrap,
} from './styles'

type Props = {
	handleModal: (change?: boolean) => void
	boardname: string
}

const PostForm = ({ handleModal, boardname }: Props) => {
	const { data: token } = useToken()

	const [type, setType] = useState('')
	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')
	const [lock, setLock] = useState(false)
	const [password, setPassword] = useState('')
	const [edit, setEdit] = useState(false)
	const [name, setName] = useState('')
	const navigate = useHistory()

	const createPost = () => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		// temp
		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}`

		if (!name || !title || !content) {
			antd.message.info('내용을 입력해주세요')
			return
		}

		if (lock && !password) {
			antd.message.info('비밀번호를 입력해주세요')
			return
		}

		API.post(apiurl, token + '', {
			type,
			title,
			name,
			content,
			lock,
			password,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
					if (lock) {
						handleModal(true)
					} else {
						navigate.push(`/ideas/${res.result.result}`)
					}
				}
			})
			.catch((err: any) => {})
	}

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>게시글 작성하기</span>
				</TitleWrapLeft>
				<TitleWrapRight>{/* <span>Home &gt; {boardname} &gt; 작성하기 </span> */}</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap></ControllerWrap>
			<TableWrap>
				<DataRow>
					<TitleHeader>제목</TitleHeader>
					<PostFormTitle>
						<antd.Input value={title} onChange={e => setTitle(e.target.value)}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					<TitleHeader>작성자명</TitleHeader>
					<PostFormTitle>
						<antd.Input value={name} onChange={e => setName(e.target.value)}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					{/* <ContentHeader>내용</ContentHeader> */}
					<Content>
						<Editor content={content} edit={edit} setContent={setContent}></Editor>
					</Content>
				</DataRow>
				{/* <DataRow>
					<ContentHeader>내용</ContentHeader>
					<Content>
						<ContentTextarea value={content} onChange={(e)=>setContent(e.target.value)}></ContentTextarea>
					</Content>
				</DataRow> */}
				<DataRow>
					<TitleHeader>비공개</TitleHeader>
					<PostFormTitle>
						<Lock>
							<antd.Space>
								<antd.Checkbox
									onChange={e => {
										setLock(e.target.checked)
										if (!e.target.checked) setPassword('')
									}}>
									비공개
								</antd.Checkbox>
							</antd.Space>
						</Lock>
						<TitleHeader>비밀번호</TitleHeader>
						<Password>
							<antd.Input
								type="password"
								readOnly={!lock}
								onChange={e => setPassword(e.target.value)}
								value={password}></antd.Input>
							{/* <Input type="password" readOnly={!lock} onChange={(e)=>setPassword(e.target.value)} value={password}></Input> */}
						</Password>
					</PostFormTitle>
				</DataRow>
			</TableWrap>
			<ButtonWrap style={{ margin: '0 auto' }}>
				<CreateButton onClick={createPost}>저장</CreateButton>
				<CancelButton onClick={() => handleModal(false)}>취소</CancelButton>
			</ButtonWrap>
			{/* <label>분류</label> <input type='text' onChange={(e)=>setType(e.target.value)}></input> */}
		</ContentWrap>
	)
}

export default PostForm
