import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	height: 340px;
	background-color: #333333;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	gap: 50px;
	padding: 0 50px;

	@media screen and (max-width: 570px) {
		padding: 0;
	}
`

export const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	flex-wrap: wrap;
	row-gap: 20px;
	border-bottom: 1px solid #444444;
	padding-bottom: 30px;

	@media screen and (max-width: 570px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		text-align: center;
	}
`

export const MenuWrap = styled.div`
	display: flex;
	gap: 30px;
`

export const Menu = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 28px;
	cursor: pointer;
	letter-spacing: -0.015em;
	&:hover {
		color: #ff8800;
	}

	@media screen and (max-width: 570px) {
		display: none;
	}
`

export const Info = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: -0.015em;
	color: #ffffff;
`

export const Bottom = styled.div`
	width: 100%;
	max-width: 1920px;
	flex-wrap: wrap;
	row-gap: 20px;
	font-size: 16px;

	@media screen and (max-width: 570px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		text-align: center;
	}
`
