import { useToken } from 'app/hooks'
import React, { useEffect, useState } from 'react'
import UserAPI from 'utils/userapi'

function Home() {
	const { data: token } = useToken()
	const [menus, setMenus] = useState<any>([])

	const getPopups = () => {
		UserAPI.get(`/popup`, token + '', {}).then(res => console.log(res))
	}

	const getMenus = () => {
		UserAPI.get(`/system/usermenus`, token + '', {}).then(res => setMenus(res.result.originalMenu))
	}

	useEffect(() => {
		getPopups()
		getMenus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div>
			<header>
				{menus?.map(m => {
					return <div>{m.name}</div>
				})}
				메뉴
			</header>
			<section>내용</section>
			<footer>-</footer>
		</div>
	)
}

export default Home
