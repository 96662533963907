import React, { useContext } from 'react'
import { IMAGES } from 'app/constants'
import ProfileSecurityHeader from '../ProfileSettingsHeader'
import AccountInfoLists from '../AccountSecurityLists'
import { ProfileSecurities } from '../styles'
import { useTranslation } from 'react-i18next'
import { useFetcher } from '../../../../hooks/fetcher'
import { useTimezone } from '../../../../hooks/timezone'
import { ThemeContext } from '../../../../../theme/ThemeProvider'

const AccountActivities = () => {
	const { t } = useTranslation()
	const { COLORS } = useContext(ThemeContext).theme

	const { data: res } = useFetcher('/user/my/recenthistory', true)

	const last = res?.result?.result?.[0]

	console.log(last)

	const data = [
		{
			image: IMAGES.avatar,
			title: t('trustedDevicesManagement'),
			content: t('youHaveTrustedDevices'),
			button: {
				title: 'management',
				goTo: '',
				modelOpen: '',
			},
		},
		{
			image: IMAGES.avatar,
			title: t('accountActivities'),
			content: t('accountAbnormalDeactivate'),
			desc: useTimezone(last?.created_at) + ' ' + t(last?.log),
			descColor: COLORS.grey,
			button: {
				title: 'more',
				goTo: '',
				modelOpen: '',
			},
		},
	]

	return (
		<ProfileSecurities>
			<ProfileSecurityHeader title={t('accountActivities')} />
			<AccountInfoLists data={data} />
		</ProfileSecurities>
	)
}

export default AccountActivities
