import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { useToken } from './hooks'

export function Routes() {
	const { i18n } = useTranslation()
	const { data: token } = useToken()

	return (
		<BrowserRouter>
			<Helmet titleTemplate="%s" defaultTitle="홈페이지" htmlAttributes={{ lang: i18n.language }}>
				<meta name="description" content="홈페이지" />
			</Helmet>

			<Switch>
				<Route
					exact
					path="/editordemo"
					component={() => {
						const xhrHtml = new XMLHttpRequest()
						xhrHtml.open('GET', './editor/editor.html', false)
						xhrHtml.send()
						const html = xhrHtml.responseText
						const xhrCss = new XMLHttpRequest()
						xhrCss.open('GET', './editor/styles.css', false)
						xhrCss.send()
						const css = xhrCss.responseText
						return (
							<>
								<style>{css}</style>
								<div dangerouslySetInnerHTML={{ __html: html }} />
							</>
						)
					}}
				/>
				<Route exact path="/" component={PAGE.Custom} />

				<Route path="/admin">
					<Route
						exact
						path="/admin/"
						component={token ? PAGE.AdminDashboardPage : PAGE.LoginPage}
					/>
					<Route
						exact
						path="/admin/login/"
						component={token ? PAGE.AdminDashboardPage : PAGE.LoginPage}
					/>
					<Route exact path="/admin/logout/" component={PAGE.LogoutPage} />
					<Route exact path="/admin/users" component={token ? PAGE.UserPage : PAGE.LoginPage} />
					<Route
						exact
						path="/admin/board"
						component={token ? PAGE.BoardManagePage : PAGE.LoginPage}
					/>
					<Route
						exact
						path="/admin/board/:id"
						component={token ? PAGE.BoardPage : PAGE.LoginPage}
					/>
					<Route
						exact
						path="/admin/board/:id/:id"
						component={token ? PAGE.PostPage : PAGE.LoginPage}
					/>
					<Route exact path="/admin/custom" component={token ? PAGE.CustomPage : PAGE.LoginPage} />
					<Route path="/custom/:id" component={token ? PAGE.Custom : PAGE.LoginPage} />

					<Route
						path="/admin/notification"
						component={token ? PAGE.NotificationPage : PAGE.LoginPage}
					/>
					<Route exact path="/admin/popup" component={token ? PAGE.PopupPage : PAGE.LoginPage} />
					<Route
						exact
						path="/admin/popup/:id"
						component={token ? PAGE.PopupPage : PAGE.LoginPage}
					/>
					<Route exact path="/admin/system" component={token ? PAGE.SystemPage : PAGE.LoginPage} />
					<Route
						exact
						path="/admin/system/:id"
						component={token ? PAGE.SystemPage : PAGE.LoginPage}
					/>
				</Route>

				{/* Secure Page */}
				{/* <Route
					exact
					path="/admin"
					component={token ? AdminDashboardPage : PAGE.LoginPage}
				/> */}
				{/* <Route exact path="/notice" component={Notice} /> */}
				<Route exact path="/:id" component={PAGE.Custom} />
				<Route exact path="/notice/:id" component={PAGE.Post} />
				{/* <Route exact path="/ideas/:id" component={Notice} />
				<Route exact path="/ideas/:id/:id" component={PostManagement} /> */}
				<Route path="*" component={PAGE.NotFoundPage} />
			</Switch>
			<GlobalStyle />
		</BrowserRouter>
	)
}
