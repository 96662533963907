import styled from 'styled-components'

export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin: 30px auto;
	min-height: 70vh;

	@media screen and (max-width: 570px) {
		min-height: 50vh;
	}
`

export const CustomPageWrap = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; */
`

export const Title = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 100%;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
`
export const Name = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 150px;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
export const Date = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 167px;
	height: 36px;

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
`

export const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	text-align: center;
	font-size: 12px;
	background: #ffffff;
`

export const TitleHeader = styled.div`
	box-sizing: border-box;

	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */

	width: 150px;
	height: 46px;
	line-height: 46px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const PostFormTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;

	min-width: 100px;
	width: 100%;
	height: 46px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Content = styled.div`
	box-sizing: border-box;

	/* width: 90%; */
	height: 500px;
	padding: 5px;
	width: 100%;
	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Lock = styled.div`
	width: 200px;
	padding: 0 30px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Password = styled.div`
	width: 100%;
	padding: 0 5px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const CancelButton = styled.button`
	padding: 0px 10px;

	width: 68px;
	text-align: center;
	height: 32px;

	/* content/accentRed */

	background: #d44242;
	border-radius: 2px;
	border: none;

	font-size: 14px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;
`

export const TextWrap = styled.div`
	display: flex;
	/* justify-content: start; */
`

export const TableWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-bottom: 16px;
	/* table/border */
`

export const DataRowWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;

	width: 95%;

	/* color/line */
	background-color: #fff8e0;
	margin: 0 10px;
	margin-top: 10px;
	border-radius: 20px;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		height: auto;
		gap: 5px;
		align-items: flex-start;
	}
`

export const FixedPost = styled.div`
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	padding: 0px;
	gap: 10px;

	width: 100%;
	height: 26px;

	height: 26px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;

	&:hover {
		cursor: pointer;
	}

	/* base/text */

	color: #333333;

	box-sizing: border-box;
`
