import React from 'react'
import { View } from 'styles/reactnative'
import PostManagementTable from './PostManagementTable'
import CommentManagementTable from '../Comment/CommentLists'
import { useWindowDimensions } from 'app/hooks'

const PostManagement = () => {
	const { height } = useWindowDimensions()
	return (
		<View
			style={{
				width: '100%',
				padding: 10,
				background: '#fff',
				paddingBottom: height / 3,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<PostManagementTable />
			<CommentManagementTable />
		</View>
	)
}

export default PostManagement
