import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiError, checkEmail } from 'utils/ExpensiveFunction'
import ErrorHandling from './ErrorHandling'
import API from 'utils/api'
import { useToken, useUser } from 'app/hooks'
// import * as fmsService from 'utils/fms'
import styled from 'styled-components'
import logo from '../../../../assets/icons/logo_footer_new.png'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'

const LoginForm = ({ setLoginSuccess }) => {
	const { t } = useTranslation()

	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const { mutate } = useToken()
	const { mutate: usernameMutate } = useUser()
	const navigate = useHistory()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const onChangeEmail = e => {
		setEmail(e.target.value)
	}

	const onChangePassword = e => {
		setPassword(e.target.value)
	}

	const handleSubmit = async () => {
		const res = checkEmail(email)
		if (typeof res === 'string') return setError(res)

		try {
			// const fmsToken = fmsService.requestPermission()

			const res = await API.send('POST', '/auth/signin', null, {
				email,
				password,
				fmsToken: '',
			})

			if (res.result.success) {
				mutate(res?.result?.token)
				usernameMutate(res?.result?.username)
				message.success('Login Succeed!')
				navigate.push('/admin')
			} else {
				const errorResult = apiError(t, res.result.msgcode)
				// toast('affff' + t(errorResult) + '')

				setError(t(errorResult))
			}
		} catch (e) {
			console.log(e, 'ERROR LOGIN')
		}
	}

	return (
		<>
			<LoginWrap>
				<LoginLogo>
					<img style={{ width: '180px', margin: "0 auto" }} alt={'images...'} src={logo}></img>
				</LoginLogo>
				<LoginTitle>관리자 로그인</LoginTitle>
				{error?.length > 3 ? <ErrorHandling success={success} error={error} /> : null}
				<LoginInputWrap>
					<LoginInput placeholder="관리자 아이디" onChange={onChangeEmail}></LoginInput>
					<LoginInput
						type="password"
						placeholder="관리자 비밀번호"
						onChange={onChangePassword}></LoginInput>
				</LoginInputWrap>
				<LoginBottomWrap>
					<LoginNotice>계정은 관리자에게 문의해주세요</LoginNotice>
					<LoginButton
						onClick={event => {
							event.preventDefault()
							handleSubmit()
						}}>
						로그인
					</LoginButton>
				</LoginBottomWrap>
			</LoginWrap>
		</>
	)
}

export default React.memo(LoginForm)

const LoginWrap = styled.div`
	/* contents */

	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 40px;
	gap: 28px;

	position: fixed;
	/* width: 100%;
	height: 100%;	 */
	width: 440px;
	max-height: 500.46px;

	left: 50%;
	top: 50%;
	margin-left: -220px;
	margin-top: -235px;

	/* white */

	background: #ffffff;
	/* main */

	border: 1px solid #2da0d2;
	border-radius: 10px;
`
const LoginLogo = styled.div`
	width: 180px;
	height: 30px;
	margin: 0 auto;
	/* margin: 0 auto; */

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
`
const LoginTitle = styled.div`
	width: 100%;
	height: 100%;
	/* max-width: 115px; */
	/* max-height: 29px; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 29px;
	/* identical to box height */

	text-align: center;

	/* black */

	color: #000000;
	font-weight: bold;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
`
const LoginInputWrap = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;

	width: 100%;
	height: 100%;
	width: 360px;
	height: 112px;

	/* Inside auto layout */

	flex: none;
	order: 2;
	flex-grow: 0;
`
const LoginInput = styled.input`
	/* input */

	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 12px 16px;

	width: 100%;
	height: 100%;
	max-width: 360px;
	max-height: 50px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

	font-size: 14px;

	&:focus {
		border: 1px solid #333333;
	}
`
const LoginBottomWrap = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 24px;

	width: 100%;
	height: 100%;
	max-width: 360px;
	max-height: 104px;

	/* Inside auto layout */

	flex: none;
	order: 3;
	flex-grow: 0;
`
const LoginNotice = styled.div`
	width: 100%;
	height: 100%;
	/* max-width: 191px;
	max-height: 20px; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	/* text/secondary */

	color: #000000;
	text-align: center;
	font-size: 14px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
`
const LoginButton = styled.button`
	/* btn */

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;

	width: 100%;
	max-width: 360px;
	/* height: 100%; */
	height: 50px;

	/* main */

	background: #2da0d2;
	border-radius: 2px;
	border: none;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

	color: #ffffff;
	font-size: 20px;
	/* letter-spacing: 1px; */
`
