import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Drawer } from 'antd'

import menu from 'assets/icons/hamburger.png'
import mobileLogo from 'assets/icons/logo_footer_new.png'
import API from 'utils/api'
import HeaderButton from '../HeaderButton'
import { HeaderContainer } from './styles'
import { Plus } from '../styles'

type Props = {
	menuHover?: boolean
}
const MobileHeader = ({ menuHover }: Props) => {
	const navigate = useHistory()
	const [open, setOpen] = React.useState(false)
	const nav = (url: string) => navigate.push(url)
	const [menus, setMenus] = React.useState<any[]>([])
	useEffect(() => {
		getMenus()
	}, [])

	const getMenus = async () => {
		try {
			const res = await API.send('GET', '/system/usermenus', null, {})

			if (res.result.success) {
				const mainMenu = res.result.originalMenu.filter(menu => menu.level === 1)

				let menuArr: any = []

				mainMenu.map(menu => {
					const subMenu = res.result.originalMenu.filter(sub => menu.id === sub.parent_menuid)
					let data: any = []
					subMenu.map(item =>
						data.push({
							title: item.name,
							goTo: `/${menu.path}/${item.path}`,
						})
					)
					const menuData = {
						...menu,
						data,
					}
					return menuArr.push(menuData)
				})

				setMenus(menuArr)
			} else {
			}
		} catch (error) {}
	}
	return (
		<>
			<HeaderContainer>
				<Plus onClick={() => nav('/')}>
					<img style={{width: "100px"}} src={mobileLogo} alt={'...'} />
				</Plus>
				<div>
					<img
						src={menu}
						onClick={() => {
							setOpen(true)
						}}
						alt={'...'}
					/>
				</div>
				<Drawer
					title="MENU"
					placement="right"
					onClose={() => {
						setOpen(false)
					}}
					open={open}>
					{menus?.map((menu, index) => (
						<HeaderButton
							key={index.toString()}
							label={menu.name}
							data={menu.data && menu.data}
							onPress={() => {
								setOpen(false)
								nav(`/${menu.path}`)
							}}
							notIcon={true}
						/>
					))}
				</Drawer>
			</HeaderContainer>
			<div style={{ height: 50 }}></div>
		</>
	)
}

export default MobileHeader
