import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as antd from 'antd'

import { useToken } from 'app/hooks/'
import API from 'utils/api'
import setting from 'assets/icons/setting.png'

import {
	Nocontent,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ContentWrap,
	ControllerWrap,
	UpdateButton,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import { PageMoveBtnWrap, Type, Active, FormEditorSection } from './styles'

import PaginatedItems from '../../components/Pagination'
import PostEditForm from './PostForm'

const CustomManagementTable = () => {
	const navigate = useHistory()
	const { data: token } = useToken()
	const [pageSize, setPageSize] = useState<number[]>([])
	const [pageid, setPageid] = useState(0)
	const [data, setData] = useState<any>()
	const [update, setUpdate] = useState(false)
	const [create, setCreate] = useState(false)
	const [customPages, setCustomPages] = useState<any[]>([])
	const [page, setPage] = useState(1)
	const [checkedList, setCheckedList] = useState<any>([])

	const changePage = (num: number) => {
		setPage(num)
	}

	const refreshList = () => {
		getPages()
	}

	const handleModal = () => {
		create && setCreate(false)
		if (update) {
			setUpdate(false)
			setData(null)
			setPageid(0)
		}
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/custom/pages/${c.id}`

			API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		antd.message.success('삭제되었습니다')
		setCheckedList([])
		refreshList()
	}
	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const deletePage = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		let apiurl = `/custom/pages/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const goToPage = (path: string) => {
		navigate.push(`/${path}`)
	}

	const getPages = () => {
		let apiurl = `/custom/pages?maxData=20&page=` + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
					setCustomPages(res.result.result.pages)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getPage = () => {
		let apiurl = `/custom/pages/${pageid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				setData(res.result)
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const UpdateCMP = (
		<PostEditForm
			handleModal={handleModal}
			data={data}
			refreshList={refreshList}
			customPages={customPages}
		/>
	)
	const CreateCMP = (
		<PostEditForm handleModal={handleModal} refreshList={refreshList} customPages={customPages} />
	)

	useEffect(() => {
		if (pageid) getPage()
	}, [pageid])

	useEffect(() => {
		if (data) setUpdate(true)
	}, [data])

	useEffect(() => {
		getPages()
	}, [page])

	return (
		<ContentWrap style={{ width: '99%' }}>
			{!create && !update ? (
				<div style={{ width: 970, maxWidth: '100%' }}>
					<TitleWrap>
						<TitleWrapLeft>
							<img src={setting} alt="icon"></img>
							<span style={{ fontSize: '20px', fontWeight: 'bold' }}>페이지관리</span>
						</TitleWrapLeft>
						<TitleWrapRight>
							<span>홈 &gt; 페이지관리</span>
						</TitleWrapRight>
					</TitleWrap>
					<ControllerWrap>
						<ButtonWrap>
							<CreateButton onClick={() => setCreate(true)}>작성</CreateButton>
							{/* <UpdateButton>선택수정</UpdateButton> */}
							<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
						</ButtonWrap>
					</ControllerWrap>
					<TableWrap>
						<DataRowWrapHeader>
							<CheckboxWrap>
								<input type="checkbox"></input>
							</CheckboxWrap>
							<NumberWrap>NO.</NumberWrap>
							<Type>분류</Type>
							<Type>페이지형태</Type>
							<Type>경로</Type>
							<Active>활성화</Active>
							<PageMoveBtnWrap>페이지확인</PageMoveBtnWrap>
							<DeleteButtonWrap>관리</DeleteButtonWrap>
						</DataRowWrapHeader>
						{customPages?.map((custom, index) => {
							return (
								<DataRowWrap key={index.toString()} onDoubleClick={() => setPageid(custom?.id)}>
									<CheckboxWrap>
										<input
											type="checkbox"
											onChange={e => checked(e.target.checked, custom.id)}></input>
									</CheckboxWrap>
									<NumberWrap>{custom?.id}</NumberWrap>
									<Type>{decodeURIComponent(custom?.title)}</Type>
									<Type>{custom?.type}</Type>
									<Type>{custom?.path}</Type>
									<Active>{custom?.active ? 'O' : 'X'}</Active>
									<PageMoveBtnWrap>
										<UpdateButton onClick={() => goToPage(custom?.path)}>확인</UpdateButton>
									</PageMoveBtnWrap>
									<DeleteButtonWrap>
										<DeleteButton onClick={() => deletePage(custom?.id)}>삭제</DeleteButton>
									</DeleteButtonWrap>
								</DataRowWrap>
							)
						})}
						{customPages?.length < 1 && <Nocontent>등록된 페이지가 없습니다</Nocontent>}
					</TableWrap>
					<PaginatedItems
						page={page}
						pageSize={pageSize}
						changePage={changePage}
						itemsPerPage={5}
					/>
				</div>
			) : (
				<FormEditorSection>{create ? CreateCMP : update ? UpdateCMP : null}</FormEditorSection>
			)}
		</ContentWrap>
	)
}

export default CustomManagementTable
