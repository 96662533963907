import React, { useState, useEffect } from 'react'
import * as antd from 'antd'
import { useToken, useUser } from 'app/hooks/'
import API from 'utils/api'
import { TitleWrap, TitleWrapLeft, TableWrap, ContentWrap } from 'styles/globalPage-styles'
import CommentForm from './CommentForm'
import CommentItems from './CommentItems'

const CommentLists = () => {
	const { data: token } = useToken()
	const { data: username } = useUser()
	const [boardid, setBoardid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0
	)
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0
	)
	const [comments, setCommnets] = useState<any>([])
	const [edit, setEdit] = useState(false)
	const [editKey, setEditKey] = useState<number | null>(null)
	const [content, setContent] = useState('')
	const [refresh, setRefresh] = useState(false)
	const [totalCount, setTotalCount] = useState(0)
	// const [content, setContent] = useState('')

	const getComments = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		setBoardid(parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0)
		setPostid(parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0)

		let apiurl = `/board/${boardPath}/${postid}/comments?maxData=20&page=` + 1

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setCommnets(res.result.result.comments)
					setTotalCount(res.result.result.totalCount)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getComments()
	}, [])

	useEffect(() => {
		if (!refresh) {
			return
		}
		getComments()
		setRefresh(false)
	}, [refresh])

	const updateComment = (commentid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments/${commentid}`

		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}

		API.put(apiurl, token + '', {
			content,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setContent('')
					setEditKey(null)
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const createComment = (content: string, lock: boolean) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}/${postid}/comments`
		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}
		API.post(apiurl, token + '', {
			content,
			username,
			lock,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const deleteComment = (id: number) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}/${postid}/comments/${id}`
		API.delete(apiurl, token + '', {}).then(() => {
			antd.message.success('삭제완료')
			getComments()
		})
	}
	return (
		<ContentWrap>
			<TitleWrap style={{ borderBottom: '1px solid gray' }}>
				<TitleWrapLeft>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>{`댓글 (${totalCount})`}</span>
				</TitleWrapLeft>
			</TitleWrap>
			<TableWrap>
				{comments.map((item, index) => (
					<CommentItems
						key={index.toString()}
						item={item}
						index={index}
						editKey={editKey}
						setEdit={setEdit}
						setEditKey={setEditKey}
						setContent={setContent}
						updateComment={updateComment}
						deleteComment={deleteComment}
					/>
				))}
			</TableWrap>
			<CommentForm createComment={createComment} />
		</ContentWrap>
	)
}

export default CommentLists
