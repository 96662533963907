import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { API_URL, UPLOAD_URL } from 'app/constants/APIKEYS'
import { useToken } from 'app/hooks'
import ClassicEditor from '@ckeditor/ckeditor-custom'
import './editor.css'

import { CkEditorSection } from '../styles'

const API = `${API_URL}/custom/pages`

type props = {
	setContent: any
	content: string
}

const Editor = ({ content, setContent }: props) => {
	const { data: token } = useToken()

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const body = new FormData()
					loader.file.then(file => {
						body.append('files', file)
						fetch(`${API}/upload`, {
							method: 'post',
							headers: {
								Authorization: 'Bearer ' + token,
							},
							body: body,
						})
							.then(res => res.json())
							.then(res => {
								resolve({
									default: `${UPLOAD_URL}/public/uploadImage/${res.result}`,
									// default: `http://3.34.30.194:3001/public/uploadImage/${res.result}`
								})
							})
							.catch(err => {
								reject(err)
							})
					})
				})
			},
		}
	}

	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = loader => {
			return uploadAdapter(loader)
		}
	}

	return (
		<CkEditorSection>
			<CKEditor
				config={{
					extraPlugins: [uploadPlugin],
				}}
				data={content ? content : ''}
				editor={ClassicEditor}
				onBlur={() => {}}
				onReady={(editor: any) => {}}
				onError={err => {
					console.log('err' + err)
				}}
				onChange={(event, editor: any) => {
					const data = editor.getData()
					setContent(data)
				}}
			/>
		</CkEditorSection>
	)
}
export default Editor
