import styled from 'styled-components'

export const ContetnWrap = styled.div<{ width: string; height: string }>`
	width: ${props => props.width};
	/* height:${props => props.height};*/
	height: auto;
	margin: 0 auto;
	/* margin: 0 auto 300px auto;	 */
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		margin: 0 auto 100px auto;
	}

	@media screen and (max-width: 570px) {
		margin: 0 auto 50px auto;
	}
`

export const CustomPageWrap = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; */
	overflow-x: hidden;
	position: relative;
`

export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin: 30px auto;
`

export const NavigationBarContainer = styled.div`
	/* width: 100%; */
	height: 50px;
	display: flex;
	justify-content: flex-start;
	gap: 100px;

	/* main */

	/* background: #2da0d2; */
`

export const HeaderButtonWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	align-items: center;
	justify-content: center;
	gap: 100px;
`

export const NavigationButtonWrap = styled.div<{ hover?: boolean }>`
	/* Gnb/list */

	transition: all 0.1s ease-in-out;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	height: 50px;
	gap: 100px;

	/* white */

	/* background: #2da0d2; */
	/* main */

	/* border-top: 3px solid #2da0d2; */

	/* Inside auto layout */

	flex: none;
	flex-grow: 1;
	max-width: 160px;

	color: #333333;

	&:hover {
		color: #2514a8;
		/* background-color: #ffffff; */
		cursor: pointer;
		font-weight: bold;
		border-bottom: 3px solid #1b14a8;
	}

	@media screen and (max-width: 570px) {
		width: 75px;
	}
`
export const NavigationButtonText = styled.span`
	font-size: 20px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	letter-spacing: 1px;

	&:hover {
		color: #1440a8;
		/* background-color: #ffffff; */
		cursor: pointer;
		font-weight: bold;
	}

	@media screen and (max-width: 570px) {
		font-size: 20px;
	}
`
export const ContetnWrapp = styled.div<{ width: string; height: string }>`
	width: ${props => props.width};
	/* height:${props => props.height};*/
	height: auto;
	margin: 0 auto 300px auto;
	position: absolute;
	top: 0;
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		margin: 0 auto 100px auto;
	}

	@media screen and (max-width: 570px) {
		margin: 0 auto 50px auto;
	}
`

export const InfoContainer = styled.div`
	width: 100%;
	margin-top: 50px;
	margin-bottom: 200px;
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 80px;

	@media screen and (max-width: 1000px) {
		gap: 14px;
		margin-bottom: 50px;
	}

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 570px) {
	}
`

export const InfoWrap = styled.div`
	@media screen and (max-width: 1000px) {
		flex-direction: column;
		gap: 50px;
		max-width: 1920px;
		width: 100%;
		display: flex;
		align-items: center;
	}
`

export const InfoTextWrap = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	padding-bottom: 50px;
	@media screen and (max-width: 1000px) {
		flex-direction: column;
		justify-content: flex-start;
		align-self: center;
		width: 100%;
	}
`

export const Header = styled.div`
	position: absolute;
	width: 100%;
	max-width: 1320px;
	display: flex;
	/* justify-content: space-evenly; */
	padding: 10px;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
`

export const HeaderContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 80px;
	top: 0;
	display: flex;
	/* justify-content: space-evenly; */
	padding: 10px;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(255, 255, 255, 0.5);
	}
`

export const WhiteHeaderContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 80px;
	top: 0;
	display: flex;
	padding: 10px;
	background-color: #ffffff;
	align-items: center;
	justify-content: center;
`
export const NewsWrap = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 30px;

	&:hover {
		cursor: pointer;
	}
`

export const Plus = styled.div`
	&:hover {
		cursor: pointer;
	}
`
