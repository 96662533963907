import styled from 'styled-components'

export const DataRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;

	width: 974px;
	height: 63px;
`
export const Top = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	/* gap: 8px; */

	width: 974px;
	height: 19px;
`
export const Info = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	/* padding: 0px; */
	gap: 10px;

	width: 185px;
	height: 19px;
`
export const Username = styled.div`
	width: 80px;
	height: 19px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	/* or 19px */

	text-align: center;

	/* text/secondary */

	color: #4e5664;
`
export const Date = styled.div`
	width: 150px;
	height: 16px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const ButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 10px;

	/* width: 58px; */
	height: 17px;
`

export const ModifyButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #0a387d;
`

export const DeleteButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #d44242;
`
export const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px 15px;

	width: 974px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	/* or 18px */

	/* text/contents */

	color: #666666;
`
