import React, { useState } from 'react'
import { Close, ButtonWrap, CreateButton, DataInputWrap } from 'styles/globalPage-styles'
import titleIcon from '../../../../../assets/icons/memberInfoIcon.png'
import close from '../../../../../assets/icons/close.png'
import * as antd from 'antd'
import { useHistory } from 'react-router'
import {
	ModalWrap,
	Modal,
	Header,
	Title,
	TableContainer,
	RowData,
	RowDataNameHeader,
	RowDataContentHeader,
	RowDataName,
	RowDataContent,
} from './styles'
type Props = {
	postPassword: string
	handleModal: () => void
}

function LockModal({ postPassword, handleModal }: Props) {
	const navigate = useHistory()
	const [password, setPassword] = useState('')

	const confirm = () => {
		if (password === postPassword) {
			handleModal()
		} else {
			alert('비밀번호가 일치하지 않습니다')
			return
		}
	}

	const closeModal = () => {
		navigate.push('/ideas')
	}

	return (
		<ModalWrap>
			<Modal>
				<Header>
					<Title>
						<img src={titleIcon} alt={'...'} />
						비밀번호
					</Title>
					<Close onClick={closeModal}>
						<img src={close} alt={'...'} />
					</Close>
				</Header>
				<TableContainer>
					<RowData>
						<RowDataNameHeader>구분</RowDataNameHeader>
						<RowDataContentHeader>내용</RowDataContentHeader>
					</RowData>
					<DataInputWrap>
						<RowDataName>비밀번호</RowDataName>
						<RowDataContent>
							<antd.Input
								type="password"
								name="password"
								onChange={e => setPassword(e.target.value)}></antd.Input>
						</RowDataContent>
					</DataInputWrap>
				</TableContainer>
				<ButtonWrap>
					<CreateButton onClick={confirm}>확인</CreateButton>
				</ButtonWrap>
			</Modal>
		</ModalWrap>
	)
}

export default LockModal
