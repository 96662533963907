import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import PaginatedItems from '../../components/Pagination'
import PopupForm from './PopupForm'
import {
	Nocontent,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ContentWrap,
	ControllerWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import setting from '../../../../../assets/icons/setting.png'
import * as antd from 'antd'
import { Active, Date, Name } from './styles'

const PopupManagementTable = () => {
	const { data: token } = useToken()
	const [create, setCreate] = useState(false)
	const [update, setUpdate] = useState(false)
	const [popups, setPopups] = useState<any[]>([])
	const [popupData, setPopupData] = useState<any>()
	const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)
	const [checkedList, setCheckedList] = useState<any>([])
	const [change, setChange] = useState(false)

	const changePage = (num: number) => {
		setPage(num)
	}

	const handleModal = () => {
		create && setCreate(false)

		if (update) {
			setUpdate(false)
			setPopupData(null)
		}
	}

	const refreshList = () => {
		getPopups()
	}

	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/popup/${c.id}`

			return API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		antd.message.success('삭제되었습니다')
		setChange(true)
	}

	useEffect(()=>{
		if(change) {
			setCheckedList([])
			getPopups()
			setChange(false)
		}
	},[change])

	const deletePopup = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		
		let apiurl = `/popup/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				refreshList()
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getPopups = () => {
		let apiurl = `/popup?maxData=20&page=` + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					if (res.result.success) {
						setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
						setPopups(res.result.result.popups)
					}
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const PopupFormCMP = (
		<PopupForm showModal={create} handleModal={handleModal} refreshList={refreshList} />
	)
	const PopupUpdateFormCMP = (
		<PopupForm
			showModal={update}
			handleModal={handleModal}
			popupData={popupData}
			refreshList={refreshList}
		/>
	)

	useEffect(() => {
		if (popupData) setUpdate(true)
	}, [popupData])

	useEffect(() => {
		getPopups()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>팝업</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 팝업</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<CreateButton onClick={() => setCreate(true)}>작성</CreateButton>
					<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input type="checkbox"></input>
					</CheckboxWrap>
					<NumberWrap>NO.</NumberWrap>
					<Name>제목</Name>
					<Date>생성일시</Date>
					<Date>종료일시</Date>
					<Active>활성화</Active>
					<Active>모바일</Active>
					<DeleteButtonWrap>관리</DeleteButtonWrap>
				</DataRowWrapHeader>
				{popups?.map((popup, index) => {
					return (
						<DataRowWrap key={index.toString()} onDoubleClick={() => setPopupData(popup)}>
							<CheckboxWrap>
								<input type="checkbox" onChange={e => checked(e.target.checked, popup.id)}></input>
							</CheckboxWrap>
							<NumberWrap>{popup?.id}</NumberWrap>
							<Name>{popup?.title}</Name>
							<Date>
								{moment(popup?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
							</Date>
							<Date>{moment(popup?.ended_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</Date>
							<Active>{popup?.active ? 'O' : 'X'}</Active>
							<Active>{popup?.mobile ? 'O' : 'X'}</Active>
							<DeleteButtonWrap>
								<DeleteButton onClick={() => deletePopup(popup?.id)}>삭제</DeleteButton>
							</DeleteButtonWrap>
						</DataRowWrap>
					)
				})}
				{popups?.length < 1 && <Nocontent>등록된 팝업이 없습니다</Nocontent>}
			</TableWrap>
			<PaginatedItems page={page} pageSize={pageSize} changePage={changePage} itemsPerPage={5} />
			{create ? PopupFormCMP : update ? PopupUpdateFormCMP : null}
		</ContentWrap>
	)
}

export default PopupManagementTable
