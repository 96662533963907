import styled from 'styled-components'

export const Close = styled.div`
	width: 16px;
	height: 16px;
	line-height: 16px;

	&:hover {
		cursor: pointer;
		font-size: bold;
	}
`

export const AdminPageContainer = styled.div`
	width: 1204px;
`

export const Nocontent = styled.div`
	width: 100%;
	text-align: center;
	font-size: 14px;
	margin: 10px 0;
`

export const ModalWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
`
export const ContentWrap = styled.div`
	width: 970px;
	max-width: 100%;
	height: 100%;
	overflow-y: hidden;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin-top: 32px;
`

export const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 39px;
	background-color: #ffffff;
	margin-bottom: 10px;
`

export const TitleWrapLeft = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
	height: 29px;
`

export const TitleWrapRight = styled.div`
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-size: 13px;

	display: flex;
	gap: 5px;
	/* identical to box height */

	/* text/contents */

	color: #666666;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
`

export const ControllerWrap = styled.div`
	/* Auto layout */
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px 0 0 0;
	border-top: 1px solid #425788;
	margin-bottom: 16px;

	background-color: #ffffff;
`
export const ButtonWrap = styled.div`
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
`

export const CreateButton = styled.button`
	/* Auto layout */
	padding: 0px 10px;

	width: 66px;
	height: 30px;

	/* content/accentBlue */

	/* border-width: 0; */
	background: #0a387d;
	border-radius: 2px;
	border: none;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	/* border: 1px solid #0a387d; */

	font-size: 14px;
	color: #ffffff;
`

export const UpdateButton = styled.button`
	/* Auto layout */
	padding: 0px 5px;

	width: 60px;
	height: 30px;
	line-height: 30px;

	/* white */

	background: #ffffff;
	/* content/accentBlue */

	border: 1px solid #0a387d;
	border-radius: 2px;
	font-size: 12px;
`

export const SDeleteButton = styled.button`
	box-sizing: border-box;

	/* Auto layout */
	padding: 0px 5px;

	width: 66px;
	height: 30px;

	/* text/white */

	background: #ffffff;
	/* content/accentRed */

	border: 1px solid #d44242;
	border-radius: 2px;
	font-size: 13px;
	color: #d44242;
`

export const SearchWrap = styled.div`
	/* Auto layout */
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 8px;

	height: 30px;
`

export const SelectDropdown = styled.select`
	/* Select/box */
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	align-items: center;
	/* padding: 0px 10px; */

	width: 90px;
	height: 30px;

	/* white */

	background: #ffffff;
	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	font-size: 12px;
	color: #999999;

	text-align: center;
`

export const SelectOption = styled.option`
	/* padding: 0px 10px; */

	width: 90px;
	height: 26px;
	text-align-last: center;
	text-align: center;
	-ms-text-align-last: center;
	-moz-text-align-last: center;

	/* main */

	&:hover {
		background-color: red;
		color: #ffffff;
	}
`

export const OptionText = styled.div`
	width: 100%;
	text-align: center;

	background-color: black;

	/* main */

	&:hover {
		background-color: #2da0d2;
		color: #ffffff;
	}
`

// const SelectOption = styled.option`
// 	color: #999999;
// 	font-size: 12px;

// 	&:hover{
//     background-color : #2DA0D2;
//     color : #ffffff
//   }
// `

export const SearchInput = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 220px;
	height: 30px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	font-size: 12px;
`
export const SearchButton = styled.button`
	/* Auto layout */
	padding: 0px 10px;

	width: 66px;
	height: 30px;

	/* bg/primary */

	border: none;
	background: #2e3031;
	border-radius: 2px;

	font-size: 12px;
	color: #ffffff;
`

export const TableWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
	margin-bottom: 16px;
	/* table/border */

	@media only screen and (max-width: 1000px) {
		padding: 0 10px;
	}
`
export const DataRowWrapHeader = styled.div`
	/* Auto layout */

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	border-top: 1px solid #e4e4e4;
	width: 100%;
	/* display: grid; */
	height: 36px;
	line-height: 36px;
	text-align: center;
	background-color: #fafafa;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const DataInputWrap = styled.div`
	/* Auto layout */

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	border-top: 1px solid #e4e4e4;

	width: 100%;
	/* display: grid; */
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: #ffffff;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

export const DataRowWrap = styled.div`
	/* Auto layout */

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	border-top: 1px solid #e4e4e4;
	width: 100%;
	/* display: grid; */
	height: 36px;
	line-height: 36px;
	text-align: center;
	background-color: #ffffff;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`
export const CheckboxWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	width: 40px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	/* padding: 10px 12px; */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const NumberWrap = styled.div`
	box-sizing: border-box;
	/* padding: 10px 12px; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* Auto layout */

	/* grid-column: 2/3; */

	/* table/bg */

	/* background: #fafafa; */
	width: 50px;
	height: 36px;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const NameWrap = styled.div`
	box-sizing: border-box;

	width: 160px;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const NicknameWrap = styled.div`
	box-sizing: border-box;

	width: 20%;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const EmailWrap = styled.div`
	box-sizing: border-box;

	width: 220px;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const PhoneWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	width: 140px;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const CreatedAtWrap = styled.div`
	box-sizing: border-box;

	width: 150px;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const DeleteButtonWrap = styled.div`
	box-sizing: border-box;

	width: 95px;
	height: 36px;
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	display: flex;
    align-items: center;
    justify-content: center;
`

export const DeleteButton = styled.button`
	box-sizing: border-box;

	/* Auto layout */
	width: 50px;
	height: 30px;
	line-height: 30px;
	/* white */

	background: #ffffff;
	/* content/accentRed */

	border: 1px solid #d44242;
	border-radius: 2px;

	font-size: 12px;
	color: #d44242;
`

export const ContentContainer = styled.div<{ isDarkMode?: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	flex-grow: 1;
`
export const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	/* flex: 1; */
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	padding: 0;
	margin: 0;
	font-size: 1.5rem;
`

export const ContentRowSplit = styled.div`
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
`
export const ContentSplit = styled.div`
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
`
export const ContentHalf = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const TrendWrapper = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
		padding-top: 20px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`
