import React from 'react'
import {
	ButtonWrap,
	Content,
	DataRow,
	Date,
	DeleteButton,
	Info,
	ModifyButton,
	Top,
	Username,
} from './styles'
import moment from 'moment'

const CommentItems = ({
	item,
	index,
	editKey,
	setEdit,
	setEditKey,
	setContent,
	updateComment,
	deleteComment,
}) => {
	return (
		<DataRow>
			<Top>
				<Info>
					<Username>{item?.writer}</Username>
					<Date>{moment(item?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</Date>
				</Info>
				<ButtonWrap>
					{editKey !== index ? (
						<ModifyButton
							onClick={() => {
								setEdit(true)
								setEditKey(index)
							}}>
							수정
						</ModifyButton>
					) : (
						<ModifyButton onClick={() => updateComment(item.id)}>완료</ModifyButton>
					)}
					<DeleteButton onClick={() => deleteComment(item.id)}>삭제</DeleteButton>
				</ButtonWrap>
			</Top>
			<Content
				contentEditable={editKey === index ? true : false}
				onInput={e => setContent(e.currentTarget.innerText)}>
				{item?.content}
			</Content>
		</DataRow>
	)
}

export default CommentItems
