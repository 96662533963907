import styled from 'styled-components'

export const HeaderContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	/* flex-direction: column; */
	justify-content: space-between;
	align-items: center;
	padding: 0px 16px;

	position: fixed;
	width: 100%;
	height: 50px;
	/* base/white */

	background: #ffffff;
	/* color/line */

	border-bottom: 1px solid #f0f0f0;
	z-index: 999;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 20px;
	width: 100%;
	height: 229px;
	background: #2b2b2b;
`

export const LogoWrap = styled.div`
	width: 80px;
	height: 16px;
	margin: 0 auto;
	font-family: 'GosanjaOTF';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: -0.015em;
	color: #afafaf;
`

export const MenuWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
	width: 90%;
	max-width: 500px;
	height: 19px;
	@media screen and (max-width: 415px) {
		gap: 5px;
		width: 100%;
	}
`

export const Menu = styled.div`
	height: 15px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #afafaf;
	@media screen and (max-width: 415px) {
		font-size: 10px;
	}
	&:hover {
		cursor: pointer;
	}
`

export const Info = styled.div`
	width: 90%;
	max-width: 700px;
	height: 45px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;
	align-items: center;
	text-align: center;
	color: #afafaf;
`

export const Title = styled.div`
	width: 100%;
	height: 26px;

	text-align: left;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: -0.015em;

	/* base/text */

	color: #333333;
`

export const Detail = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;

	width: 100%;
	height: 84px;
`

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 20px;

	width: 100%;
	text-align: left;
	height: 20px;
`

export const ItemName = styled.div`
	width: auto;
	height: 20px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	/* identical to box height */

	letter-spacing: -0.015em;

	/* base/text */

	color: #333333;
`

export const ItemColumn = styled.div`
	width: auto;
	height: 20px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	/* identical to box height */

	letter-spacing: -0.015em;

	/* base/text */

	color: #333333;
`
