import BigNumber from 'bignumber.js'

/* eslint-disable no-useless-escape */
export default class CIUtils {
	static stockid(id: number) {
		let n: string = id + ''
		return n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n
	}

	static stockidFromResult(result: any) {
		if (!result) return result

		result.map((item: any) => {
			this.stockid(item.id)
			return result
		})

		return result
	}

	static validateEnglish = value => {
		const regex = /^[0-9a-zA-Z]{1,20}$/
		return regex.test(value)
	}

	static validateEmail = email => {
		const regex =
			/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
		return regex.test(email)
	}

	static validateName = name => {
		const regex = /^[0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]{1,20}$/
		return regex.test(name)
	}

	static validateNumber = number => {
		const regex = /^[0-9]{9,12}$/
		return regex.test(number)
	}

	static validatePassword = password => {
		const regex = /[0-9a-zA-Z]{1,}[^0-9a-zA-Z]{1,}/
		return regex.test(password)
	}

	static removeWhitespace = text => {
		const regex = /\s/g
		return text.replace(regex, '')
	}

	static padId(id: number) {
		let n: string = id + ''
		return '43' + (n.length >= 6 ? n : new Array(6 - n.length + 1).join('0') + n)
	}
}

export const getPercentageFromPrice = (price: number, min: number, max: number) => {
	return Math.ceil(((price - min) / (max - min)) * 100)
}

export const padDP = (number: number, dp: number) => {
	try {
		if (!dp) dp = 1
		const n = new BigNumber(number)
		return n.toFormat(dp)
	} catch (e) {
		console.error(e)
		return number.toFixed(dp)
	}
}

export const toShortNumber = (value: number | string, format: number = 2) => {
	const number = new BigNumber(value)
	let shortNumber: any = number

	if (number.isGreaterThanOrEqualTo(1e12)) {
		shortNumber = number.dividedBy(1e12).toFixed(format) + 'T'
	} else if (number.isGreaterThanOrEqualTo(1e9)) {
		shortNumber = number.dividedBy(1e9).toFixed(format) + 'B'
	} else if (number.isGreaterThanOrEqualTo(1e6)) {
		shortNumber = number.dividedBy(1e6).toFixed(format) + 'M'
	} else if (number.isGreaterThanOrEqualTo(1e3)) {
		shortNumber = number.dividedBy(1e3).toFixed(format) + 'K'
	} else {
		shortNumber = number.toFormat(format)
	}

	return shortNumber.toString()
}
