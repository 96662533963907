import styled from 'styled-components'

export const Form = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 0;
	width: 100%;
`

export const UserData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	height: 47px;
	line-height: 47px;
	background: #ffffff;
`

export const ColumnTitle = styled.div`
	box-sizing: border-box;
	width: 100px;
	height: 50px;
	font-size: 12px;
	text-align: center;
	color: #999999;
	background: #fafafa;
	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ContentTitle = styled.div`
	box-sizing: border-box;
	width: 370px;
	height: 50px;
	background: #fafafa;
	font-size: 12px;
	text-align: center;
	color: #999999;
	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Column = styled.div`
	box-sizing: border-box;
	width: 100px;
	height: 50px;
	font-size: 12px;
	text-align: center;
	color: #666666;
	background: #ffffff;
	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Content = styled.div`
	box-sizing: border-box;
	padding: 0 5px;
	width: 370px;
	height: 50px;
	background: #ffffff;
	font-size: 13px;
	color: #666666;
	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const CustomDataColumn = styled.div`
	box-sizing: border-box;
	width: 100px;
	height: 60px;
	line-height: 60px;
	font-size: 12px;
	text-align: center;
	color: #666666;
	background: #ffffff;
	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const ContentTextarea = styled.div`
	width: 370px;
	height: 60px;
	padding: 5px 10px;
	overflow-x: hidden;
	overflow-y: auto;
	resize: none;
	background: #ffffff;
	font-size: 13px;
	color: #666666;
	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0px;
	gap: 8px;
	text-align: center;
	width: 100%;
	height: 30px;
`

export const UpdateButton = styled.button`
	padding: 0px 10px;
	width: 50%;
	height: 30px;
	margin: 15px auto;
	border: none;
	background: #0a387d;
	border-radius: 2px;
	font-size: 12px;
	font-weight: 500;
	color: #ffffff;
`

export const SaveButton = styled.button`
	padding: 0px 10px;
	width: 50%;
	height: 30px;
	margin: 10px auto;
	font-size: 12px;
	line-height: 17px;
	color: #ffffff;
	background: #0a387d;
	border: none;
	border-radius: 2px;
`

export const CancelButton = styled.button`
	padding: 0px 10px;
	width: 66px;
	height: 30px;
	font-size: 12px;
	line-height: 17px;
	color: #ffffff;
	background: #d44242;
	border: none;
	border-radius: 2px;
`

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;
	isolation: isolate;

	position: absolute;
	width: 510px;

	left: 50%;
	top: 15%;
	margin-left: -255px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`
