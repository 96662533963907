import styled from 'styled-components'

export const HeaderLogo = styled.div`
	/* width: 400px; */
	height: 34.62px;
	margin-left: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
`

export const LogoContainer = styled.div`
	/* position: absolute; */
	/* width: 100%; */
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* padding: 15px 0;
	 */
	padding: 15px 0px 15px 25px;
	/* background-color: #ffffff; */
`
