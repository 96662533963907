import React from 'react'
import { useHistory } from 'react-router-dom'
import Logo from 'assets/icons/logo_footer_new.png'
import RightLogo from 'assets/icons/right_logo.png'
import { Container, Top, MenuWrap, Menu, Bottom, Info } from './styles'

const Footer = () => {
	const navigate = useHistory()

	const nav = (url: string) => navigate.push(url)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const pageMove = (url: string) => {
		window.open(url)
	}

	return (
		<>
			<Container>
				<Top>
					<div>
						<img style={{width:"150px"}} src={Logo} alt={'....'} />
						{/* <SocialIconWrap>
							<img src={Tele} onClick={() => pageMove('https://t.me/CILABS_kr')}></img>
							<img src={Kakao} onClick={() => pageMove('http://pf.kakao.com/_DxnKcxj')}></img>
						</SocialIconWrap> */}
					</div>
					<MenuWrap>
						<Menu onClick={() => nav('/serviceterms')}>서비스 이용약관</Menu>
						<Menu onClick={() => nav('/financialterms')}>전자금융 거래약관</Menu>
						<Menu onClick={() => nav('/personalpolicy')}>개인정보처리방침</Menu>
						<Menu onClick={() => nav('/inquiry')}>고객문의</Menu>
					</MenuWrap>
				</Top>
				<Bottom>
					<Info>
						<p>
							모아앤덤 | 대표: 경홍찬 | 경기도 평택시 중앙2로 13, 602-1-972호(평택동) 
						</p>
						<p>사업자등록번호 : 312-87-03065</p>
					</Info>
					<img style={{ height: 'fit-content' }} alt={'....'} src={RightLogo} />
				</Bottom>
			</Container>
		</>
	)
}

export default Footer
