import React, { useEffect } from 'react'
import { useToken } from 'app/hooks'
import { useHistory } from 'react-router-dom'

export const LogoutPage = () => {
	const { mutate } = useToken('', false)
	const navigate = useHistory()

	useEffect(() => {
		mutate(null)
		navigate.push('/admin/login')
	}, [])

	return null
}
