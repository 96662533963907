import React from 'react'
import { useHistory } from 'react-router-dom'
import logo from 'assets/icons/logo_new2.png'
import greenLogo from 'assets/icons/logo_new2.png'
import { LogoContainer, HeaderLogo } from './styles'

type Props = {
	menuHover?: boolean
}
const Logo = ({ menuHover }: Props) => {
	const navigate = useHistory()

	const nav = (url: string) => navigate.push(url)

	return (
		<>
			<LogoContainer>
				<HeaderLogo
					onClick={() => {
						nav('/')
					}}>
					{menuHover ? (
						<img style={{height:"50px"}}src={greenLogo} alt={'logo'} height={'80%'} />
					) : (
						<img style={{height:"50px"}} src={logo} alt={'logo'} height={'80%'} />
					)}
				</HeaderLogo>
			</LogoContainer>
		</>
	)
}

export default Logo
