import moment from 'moment'
import React from 'react'
import {
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
} from 'styles/globalPage-styles'
import { Date, Name, Title } from './styles'
const BoardPost = ({ item, checked, deletePost, goToPost }) => {
	return (
		<DataRowWrap>
			<CheckboxWrap>
				<input type="checkbox" onChange={e => checked(e.target.checked, item.id)}></input>
			</CheckboxWrap>
			<NumberWrap>{item.id}</NumberWrap>
			<Title onClick={() => goToPost(item?.id)}>{decodeURIComponent(item?.title)}</Title>
			<Name>{item?.writer}</Name>
			<Date>{moment(item?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</Date>
			<DeleteButtonWrap>
				<DeleteButton onClick={() => deletePost(item?.id)}>삭제</DeleteButton>
			</DeleteButtonWrap>
		</DataRowWrap>
	)
}

export default BoardPost
