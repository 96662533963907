import styled from 'styled-components'

export const MapContainer = styled.div`
	width: 1360px;
	height: 740px;

	@media screen and (max-width: 1000px) {
		width: 100%;
		height: 400px;
		max-width: 560px;
		min-height: 360px;
		max-height: 680px;
	}
`

export const ButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 20px;
	width: 100%;
	height: 40px;
	margin-bottom: 20px;
	flex: none;
	order: 0;
	flex-grow: 0;
	&:hover {
		cursor: pointer;
	}
`

export const ClickedButton = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 120px;
	height: 40px;
	border: 0.5px solid #14a878;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.015em;
	color: #14a878;
`

export const Button = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 120px;
	height: 40px;
	border: 0.5px solid #b5b5b5;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.015em;
	color: #333333;
`
