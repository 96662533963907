import styled from 'styled-components'

export const PageMoveBtnWrap = styled.div`
	box-sizing: border-box;

	width: 95px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	display: flex;
    align-items: center;
    justify-content: center;
`

export const Type = styled.div`
	box-sizing: border-box;

	width: 200px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`

export const Active = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	/* table/border */
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	/* identical to box height, or 16px */

	text-align: center;
`
export const FormEditorSection = styled.div`
	width: 80%;
	height: 100%;
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
	@media only screen and (min-width: 600px) {
		width: 100%;
	}
	@media only screen and (min-width: 768px) {
		width: 100%;
	}
	@media only screen and (min-width: 992px) {
		width: 80% !important;
	}
	@media only screen and (min-width: 1200px) {
		width: 80% !important;
	}
`
