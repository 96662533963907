import styled from 'styled-components'
export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin: 30px auto;
	min-height: 70vh;

	@media screen and (max-width: 570px) {
		min-height: 50vh;
	}
`

export const TextWrap = styled.div`
	display: flex;
	/* justify-content: start; */
	align-items: center;
`
export const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 39px;
	background-color: #ffffff;
	margin-bottom: 70px;

	@media only screen and (max-width: 1000px) {
		width: 100%;
	}
`

export const TitleWrapLeft = styled.div`
	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 6px;
	height: 29px;

	@media only screen and (max-width: 1000px) {
		display: none;
	}
`

export const TitleWrapRight = styled.div`
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-size: 13px;

	display: flex;
	gap: 5px;
	/* identical to box height */

	/* text/contents */

	color: #666666;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

	@media only screen and (max-width: 1000px) {
		/* width: 100%; */
		width: 300px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
	}
`

export const TableWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
	margin-bottom: 16px;
	/* table/border */
`

export const SearchButtonWrap = styled.div`
	&:hover {
		cursor: pointer;
	}
`
export const DataRowWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	gap: 60px;

	width: 100%;
	height: 80px;

	/* color/line */

	border-bottom: 1px solid #f0f0f0;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		height: auto;
		gap: 5px;
		align-items: flex-start;
	}
`

export const NoticeTWrap = styled.div`
	box-sizing: border-box;

	padding: 0 12px 0 0;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	/* padding: 10px 12px; */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	/* Auto layout */

	/* grid-column: 2/3; */

	/* table/bg */

	/* background: #fafafa; */
	width: 80px;
	height: 36px;
	/* table/border */

	@media screen and (max-width: 1000px) {
		justify-content: start;
		width: auto;
		padding: 0;
		padding-right: 12px;
	}
`

export const NumberWrap = styled.div`
	box-sizing: border-box;

	padding: 0 12px 0 0;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	/* padding: 10px 12px; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* Auto layout */

	/* grid-column: 2/3; */

	/* table/bg */

	/* background: #fafafa; */
	width: 80px;
	height: 36px;
	/* table/border */

	@media screen and (max-width: 1000px) {
		display: none;
	}
`

export const Title = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	padding: 0px;
	gap: 10px;

	/* width: 100%; */
	height: 26px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	/* font-weight: 700; */
	font-size: 20px;
	line-height: 26px;

	text-align: left;

	&:hover {
		cursor: pointer;
	}

	@media screen and (max-width: 1000px) {
		font-size: 16px;
	}
`

export const Date = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 167px;
	height: 36px;

	text-align: center;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	/* font-weight: 700; */
	font-size: 16px;
	line-height: 26px;
	display: flex;

	@media screen and (max-width: 1000px) {
		padding: 0;
	}
`

export const FixedPost = styled.div`
	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	padding: 0px;
	gap: 10px;

	/* width: 100%; */
	height: 26px;

	height: 26px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;

	&:hover {
		cursor: pointer;
	}

	/* base/text */

	color: #333333;

	box-sizing: border-box;

	@media screen and (max-width: 1000px) {
		font-size: 16px;
	}
`
