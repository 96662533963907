import { useToken } from 'app/hooks'
import React, { useState } from 'react'
// import { Text } from 'styles/reactnative'
import { StyledButton } from './styles'

interface IProps {
	title?: string
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	style?: any
	border?: { width: string; color?: string; style?: string }
	backgroundHover?: any
	textHover?: any
	icon?: any
	iconLeft?: boolean
	size?: number
	textStyle?: React.CSSProperties
	ref?: any
	className?: any
}
const CButton = ({
	title,
	icon,
	onClick,
	style,
	border,
	backgroundHover,
	textHover,
	iconLeft,
	size,
	textStyle,
	ref,
	className,
}: IProps) => {
	return (
		<StyledButton
			size={size}
			ref={ref}
			className={'customBtn' + className}
			type="button"
			backgroundHover={backgroundHover}
			textHover={textHover}
			style={{
				borderStyle: border?.style !== undefined ? border.style : 'solid',
				borderWidth: border?.width !== undefined ? border.width : 0,
				borderColor: border?.color !== undefined ? border.color : 'transparent',
				display: 'flex',
				flexDirection: icon ? 'row' : 'column',
				alignItems: 'center',
				alignSelf: 'center',
				justifyContent: 'center',
				...style,
			}}
			onClick={onClick}>
			{iconLeft && icon ? icon : null}
			{/* <Text style={{ ...textStyle }}></Text> */}
			{title}
			{!iconLeft && icon ? icon : null}
		</StyledButton>
	)
}

export default React.memo(CButton)
