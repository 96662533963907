import React from 'react'
import {
	InfoContainer,
	InfoTextWrap,
	InfoWrap,
	Item,
	ItemColumn,
	ItemName,
	Left,
	Right,
} from './styles'
import KakaoMap from '../KakaoMap'

const CustomInfo = () => {
	return (
		<InfoContainer>
			<InfoWrap>
				<KakaoMap />
				<InfoTextWrap>
					<Left>평택본사</Left>
					<Right>
						<Item>
							<ItemName>Add</ItemName>
							<ItemColumn>경기도 평택시 중앙2로 13, 602-1-972호(평택동)</ItemColumn>
						</Item>
						<Item>
							<ItemName>E-mail</ItemName>
							<ItemColumn>moandom4@moandom.com</ItemColumn>
						</Item>
					</Right>
					
				</InfoTextWrap>
			</InfoWrap>
		</InfoContainer>
	)
}

export default CustomInfo
