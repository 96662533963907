import styled from 'styled-components'

export const InfoContainer = styled.div`
	width: 100%;
	margin-top: 50px;
	margin-bottom: 200px;
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 80px;
	@media screen and (max-width: 1000px) {
		gap: 14px;
		margin-bottom: 50px;
	}

	@media screen and (max-width: 900px) {
	}

	@media screen and (max-width: 570px) {
	}
`

export const InfoTextWrap = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	padding-bottom: 50px;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		justify-content: flex-start;
		align-self: center;
		width: 100%;
	}
`

export const InfoWrap = styled.div`
	@media screen and (max-width: 1000px) {
		flex-direction: column;
		gap: 50px;
		max-width: 1920px;
		width: 100%;
		display: flex;
		align-items: center;
	}
`

export const Left = styled.div`
	width: 120px;
	height: 32px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: -0.015em;
	color: #333333;
`

export const Right = styled.div`
	display: flex;
	gap: 50px;
`

export const Item = styled.div`
	display: flex;
	gap: 20px;
`

export const ItemName = styled.div`
	width: auto;
	height: 26px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: -0.015em;
	color: #333333;
`

export const ItemColumn = styled.div`
	width: auto;
	height: 21px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.015em;
	color: #333333;
`
