import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar, Pagination } from 'swiper'
import 'swiper/css'
import { Text, View } from 'styles/reactnative'
import { CButton, CImage } from '../Common'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons'
import { SliderComponentSection, SliderLeftSection, SliderRightSection } from './styles'
import { useWindowDimensions } from 'app/hooks'

const SliderComponent = ({ images }: any) => {
	const { width } = useWindowDimensions()
	const prevRef = useRef(null)
	const nextRef = useRef(null)
	const swiperRef = useRef<SwiperCore>()
	SwiperCore.use([Navigation, Scrollbar, Pagination])
	const swiperOptions: any = {
		slidesPerView: 'auto',
		spaceBetween: 20,
		grabCursor: true,
		mousewheel: true,
		navigation: {
			prevEl: prevRef.current,
			nextEl: nextRef.current,
		},

		onBeforeInit: (swiper: any) => {
			swiperRef.current = swiper
		},

		// Add any other Swiper options you need
	}

	return (
		<SliderComponentSection>
			<SliderLeftSection>
				<View data-aos="fade-up" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
					<Text size={24} style={{ fontWeight: 'bold' }}>
						PROMISE
					</Text>
					<Text
						size={16}
						style={{
							fontWeight: '300',
							lineHeight: 1.5,
							marginTop: width < 900 ? 0 : 20,
							width: width < 900 ? '80%' : '100%',
						}}>
						신뢰할수 있는 기업 모아앤덤과 함께해주세요.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', paddingBottom: 30 }}>
					<CButton
						onClick={() => swiperRef.current?.slidePrev()}
						icon={<IconArrowLeft color={'#6FD3AE'} />}
						style={{
							width: 37,
							height: 37,
							borderRadius: 50,
							justifyContent: 'center',
							alignItems: 'center',
							borderWidth: 1,
							borderColor: '#6FD3AE',
							marginRight: 20,
						}}
					/>

					<CButton
						onClick={() => swiperRef.current?.slideNext()}
						className="review-swiper-button-next"
						icon={<IconArrowRight color={'#6FD3AE'} />}
						style={{
							width: 37,
							height: 37,
							borderRadius: 50,
							justifyContent: 'center',
							alignItems: 'center',
							borderWidth: 1,
							borderColor: '#6FD3AE',
						}}
					/>
				</View>
			</SliderLeftSection>
			<SliderRightSection>
				<Swiper {...swiperOptions}>
					{images?.map((item, index) => (
						<SwiperSlide style={{ width: 280, height: 330 }} key={index.toString()}>
							<View
								data-aos="fade-left"
								data-aos-delay="500"
								data-aos-duration="2000"
								data-aos-anchor-placement="right-center"
								style={{ width: '100%', height: '100%' }}>
								<CImage alt={`${index} +1`} src={item} style={{ width: '100%', height: '100%' }} />
							</View>
						</SwiperSlide>
					))}
				</Swiper>
			</SliderRightSection>
		</SliderComponentSection>
	)
}

export default SliderComponent
