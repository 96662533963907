import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from 'utils/api'
import {
	ContentWrap,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	SearchButtonWrap,
	TableWrap,
	DataRowWrap,
	TextWrap,
	NoticeTWrap,
	FixedPost,
	Date,
	NumberWrap,
	Title,
} from './styles'
import moment from 'moment'
import { Nocontent } from 'styles/globalPage-styles'
import PaginatedItems from '../../Paginations'
import PostForm from '../Board/PostForm'
import search from 'assets/icons/searchicon.svg'

const Notice = () => {
	const [pageSize, setPageSize] = useState<number[]>([])
	const [posts, setPosts] = useState<any[]>([])
	const [boardname, setBoardname] = useState('')
	const [page, setPage] = useState(1)
	const navigate = useHistory()
	const [create, setCreate] = useState(false)
	const [searchWord, setSearchWord] = useState('')

	const refreshList = () => {
		getPosts()
	}

	const searchEvent = () => {
		getPosts(1)
	}

	const getPosts = (pageNum?: number) => {
		let pageNumber = 1

		if (pageNum) {
			pageNumber = pageNum
		} else {
			pageNumber = page
		}

		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		// let apiurl = `/board/${boardPath}?maxData=20&page=` + 1
		let apiurl =
			`/board/${boardPath}?type=title&searchWord=${searchWord}&maxData=20&page=` + pageNumber

		API.get(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
					//더미
					// setPosts(POSTS)
					setPosts(res.result.result.posts)
					setBoardname(res.result.result.boardname)
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const handleModal = (change?: boolean) => {
		if (change) {
			getPosts()
		}
		setCreate(false)
	}

	const deletePost = (id: number) => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		// temp
		const boardPath = 'notice'
		let apiurl = `/board/${boardPath}/${id}`

		API.delete(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const changePage = (num: number) => {
		setPage(num)
	}

	const goToPost = (postid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const name = encodeURI(encodeURIComponent(boardname))
		// navigate.push(`/board/${boardPath}/${postid}?name=${name}`);
		navigate.push(`/notice/${postid}?name=${name}`)
	}

	useEffect(() => {
		getPosts()
	}, [])
	return (
		<>
			{!create ? (
				<ContentWrap style={{ marginBottom: '150px', padding: '0 10px' }}>
					<TitleWrap>
						<TitleWrapLeft>
							<span>총 {posts?.length}건</span>
						</TitleWrapLeft>

						<TitleWrapRight>
							<div
								style={{
									display: 'flex',
									width: '50px',
									borderBottom: '1px solid #333333',
									paddingBottom: '9px',
								}}>
								<select style={{ border: 'none' }}>
									<option>제목</option>
								</select>
							</div>
							<div style={{ display: 'flex', borderBottom: '1px solid #333333' }}>
								<input
									type="text"
									style={{ border: 'none' }}
									onChange={e => setSearchWord(e.target.value)}></input>
								<SearchButtonWrap onClick={searchEvent}>
									<img src={search}></img>
								</SearchButtonWrap>
							</div>
						</TitleWrapRight>
					</TitleWrap>
					<TableWrap>
						{posts?.map((post, index) => {
							if (post?.fix) {
								return (
									<DataRowWrap>
										<TextWrap>
											<NoticeTWrap style={{ color: '#184fdb' }}>공지</NoticeTWrap>
											<FixedPost
												onClick={() => {
													goToPost(post?.id)
												}}>
												{post?.title}
											</FixedPost>
										</TextWrap>
										<Date>{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}</Date>
									</DataRowWrap>
								)
							} else {
								return (
									<DataRowWrap>
										<div style={{ display: 'flex' }}>
											<NumberWrap>{post?.id}</NumberWrap>
											<Title
												onClick={() => {
													goToPost(post?.id)
												}}>
												{post?.title}
											</Title>
										</div>
										<Date>{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}</Date>
									</DataRowWrap>
								)
							}
						})}
						{posts?.length < 1 && <Nocontent>등록된 게시물이 없습니다</Nocontent>}
					</TableWrap>
					<PaginatedItems
						page={page}
						pageSize={pageSize}
						changePage={changePage}
						itemsPerPage={5}
					/>
				</ContentWrap>
			) : (
				<PostForm handleModal={handleModal} boardname={boardname} />
			)}
		</>
	)
}

export default Notice
