import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
	Nocontent,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ContentWrap,
	ControllerWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import { Date, Sender, Content, Type } from './styles'
import API from 'utils/api'
import { useToken } from 'app/hooks/'
import NotificationModal from './NotificationModal'
import NotificationWriteForm from './NotificationWriteForm'
import PaginatedItems from '../../components/Pagination'
import setting from '../../../../../assets/icons/setting.png'
import * as antd from 'antd'

const NotificationManagementTable = () => {
	const { data: token } = useToken()
	const [notifications, setNotifications] = useState<any[]>([])
	const [notificationId, setNotificationId] = useState<number | null>(null)
	const [detail, setDetail] = useState<any[]>([])
	const [modal, setModal] = useState(false)
	const [writeForm, setWriteForm] = useState(false)
	const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)
	const [checkedList, setCheckedList] = useState<any>([])

	const changePage = (num: number) => {
		setPage(num)
	}

	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const handleModal = () => {
		setModal(!modal)
		setNotificationId(null)
	}

	const handleWriteForm = () => {
		setWriteForm(!writeForm)
		getNotifications()
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/notification/${c.id}`

			API.delete(apiurl, token + '', {})
				.then((res: any) => {
					getNotifications()
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		antd.message.success('삭제되었습니다')
		setCheckedList([])
		// getNotifications()
	}

	const deleteNotification = (notificationid: any) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		let apiurl = `/notification/${notificationid}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					getNotifications()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getNotifications = () => {
		let apiurl = `/notification?maxData=20&page=` + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setNotifications(res.result.result.notifications)
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getNotification = () => {
		let apiurl = `/notification/${notificationId}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setDetail(res.result.result)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	useEffect(() => {
		console.log(detail)
		if (detail.length > 0) handleModal()
	}, [detail])

	useEffect(() => {
		if (notificationId) getNotification()
	}, [notificationId])

	useEffect(() => {
		getNotifications()
	}, [page])
	// ContentWrap
	return (
		<ContentWrap>
			{!writeForm ? (
				<div>
					<TitleWrap>
						<TitleWrapLeft>
							<img src={setting} alt="icon"></img>
							<span style={{ fontSize: '20px', fontWeight: 'bold' }}>알림 전송 목록</span>
						</TitleWrapLeft>
						<TitleWrapRight>
							<span>홈 &gt; 알림</span>
						</TitleWrapRight>
					</TitleWrap>
					<ControllerWrap>
						<ButtonWrap>
							<CreateButton onClick={handleWriteForm}>발송</CreateButton>
							<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
						</ButtonWrap>
					</ControllerWrap>
					<TableWrap>
						<DataRowWrapHeader>
							<CheckboxWrap>
								<input type="checkbox"></input>
							</CheckboxWrap>
							<NumberWrap>NO.</NumberWrap>
							<Type>분류</Type>
							<Content>내용</Content>
							<Date>수신일/발신일</Date>
							<Sender>보낸이</Sender>
							<DeleteButtonWrap>관리</DeleteButtonWrap>
						</DataRowWrapHeader>
						{notifications?.map((notification, index) => {
							return (
								<DataRowWrap
									key={index.toString()}
									onDoubleClick={() => setNotificationId(notification?.notificationid)}>
									<CheckboxWrap>
										<input
											type="checkbox"
											onChange={e =>
												checked(e.target.checked, notification.notificationid)
											}></input>
									</CheckboxWrap>
									<NumberWrap>{notification?.id}</NumberWrap>
									<Type>{notification?.filter}</Type>
									<Content>{notification?.content}</Content>
									<Date>
										{moment(notification?.created_at)
											.tz('Asia/Seoul')
											.format('YYYY-MM-DD HH:mm:ss')}
									</Date>
									<Sender>{notification?.sender}</Sender>
									<DeleteButtonWrap>
										<DeleteButton onClick={() => deleteNotification(notification?.notificationid)}>
											삭제
										</DeleteButton>
									</DeleteButtonWrap>
								</DataRowWrap>
							)
						})}
						{notifications?.length < 1 && <Nocontent>등록된 알림이 없습니다</Nocontent>}
					</TableWrap>
					<PaginatedItems
						page={page}
						pageSize={pageSize}
						changePage={changePage}
						itemsPerPage={5}
					/>
					{modal && <NotificationModal handleModal={handleModal} data={detail} />}
				</div>
			) : (
				<NotificationWriteForm handleWriteForm={handleWriteForm} />
			)}
		</ContentWrap>
	)
}

export default NotificationManagementTable
